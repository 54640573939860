import React from 'react';
import styled from 'styled-components';

const WistiaImage = styled.img`
  filter: blur(5px);
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const WistiaEmbed = styled.div`
  height: 400px;
  width: 640px;
  z-index: 1;
  position: relative;
`;

const WistiaSwatch = styled.div`
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: opacity 200ms;
  width: 100%;
`;

export const VideoPreview = ({ wistiaId }) => {
  return (
    <WistiaEmbed className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}>
      <WistiaSwatch className="wistia_swatch">
        <WistiaImage
          src={`https://fast.wistia.com/embed/medias/${wistiaId}/swatch`}
          alt=""
          aria-hidden="true"
        />
      </WistiaSwatch>
    </WistiaEmbed>
  );
};
