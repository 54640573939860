import React from 'react';
import PT from 'prop-types';
import { Box, colors, LinkButton, useI18nContext } from '@procore/core-react';
import styled from 'styled-components';
import UserSection from './UserSection';

const FooterContainer = styled(Box)`
  background-color: ${colors.white};
  border-top: 1px solid ${colors.gray90};
`;

const MenuFooter = ({
  isSignedIn,
  signInPath,
  currentUser,
  currentCompany,
  changeCompanyPath,
  signOutPath,
}) => {
  const i18n = useI18nContext();

  return (
    <FooterContainer padding="xl">
      {!isSignedIn ? (
        <LinkButton href={signInPath} block size="lg">
          {i18n.t('labels.login')}
        </LinkButton>
      ) : (
        <UserSection
          currentUser={currentUser}
          currentCompany={currentCompany}
          changeCompanyPath={changeCompanyPath}
          signOutPath={signOutPath}
        />
      )}
    </FooterContainer>
  );
};

MenuFooter.propTypes = {
  isSignedIn: PT.bool.isRequired,
  signInPath: PT.string.isRequired,
  currentUser: PT.shape({}),
  currentCompany: PT.shape({}),
  changeCompanyPath: PT.string,
  signOutPath: PT.string,
};
export default MenuFooter;
