import React from 'react';
import { Box, Card, colors, Flex, H3, P } from '@procore/core-react';
import PT from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

import { truncate } from '@/react/shared/utils/helpers';
import { UnstyledLink } from '@/react/styles';
import { COMPONENT_MAP } from '@/react/consts';

const BottomBorderFlex = styled(Flex)`
  border-bottom: 1px solid ${colors.gray90};
  min-height: 100px;
  width: 100%;
`;

const StyledComponentsText = styled(P)`
  color: ${colors.blue70};
  margin: 0;
`;

// IE specific CSS fix to prevent
// text from overflowing container
const FlexFullWidthChildren = styled(Flex)`
  width: 100%;
  & > * {
    width: 100%;
  }
`;

const renderComponentString = (components, isBeta) => {
  const mappedComponents = components
    .map((component) => COMPONENT_MAP[component])
    .filter(Boolean);
  if (isBeta) {
    mappedComponents.push('Beta');
  }

  return mappedComponents.join(' | ');
};

const ListingsAppCard = ({ app }) => (
  <UnstyledLink href={`/apps/${app.slug}`} variant="gray">
    <Card variant="hoverable">
      <Flex
        direction="column"
        padding="xl"
        style={{ minHeight: '350px', minWidth: '275px' }}
      >
        <BottomBorderFlex padding="none xl xl xl" justifyContent="center">
          {app.image_url && (
            <LazyLoad
              height={100}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100px',
              }}
            >
              <img
                src={app.image_url}
                style={{
                  width: '100%',
                  maxHeight: '100%',
                }}
              />
            </LazyLoad>
          )}
        </BottomBorderFlex>
        <FlexFullWidthChildren
          direction="column"
          paddingTop="lg"
          grow="1"
          justifyContent="space-evenly"
        >
          <H3>{app.name}</H3>
          <Flex
            alignItems="center"
            margin="xs none sm none"
            style={{ minHeight: '45px' }}
          >
            <span>{truncate(app.search_description, 100, true)}</span>
          </Flex>
          <Box>
            <StyledComponentsText>
              {renderComponentString(app.components, app.status === 'beta')}
            </StyledComponentsText>
          </Box>
        </FlexFullWidthChildren>
      </Flex>
    </Card>
  </UnstyledLink>
);

ListingsAppCard.propTypes = {
  app: PT.shape({
    components: PT.arrayOf(PT.string).isRequired,
    image_url: PT.string.isRequired,
    slug: PT.string.isRequired,
    name: PT.string.isRequired,
  }).isRequired,
};

export default ListingsAppCard;
