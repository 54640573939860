import React from 'react';
import PT from 'prop-types';
import { I18nProvider } from '@procore/core-react';
import { AppContextProvider } from '@/react/context/AppContext';
import { SearchProvider } from '@/react/context/SearchContext';
import { Navbar } from '@/react/components';
import i18n from '../i18n';
import FAQ from './FAQ';

const FAQContainer = ({ faqs, header }) => {
  return (
    <I18nProvider locale={i18n.locale} translations={i18n.translations}>
      <SearchProvider>
        <AppContextProvider header={header}>
          <Navbar isSearchVisible />
          <FAQ faqs={faqs} />
        </AppContextProvider>
      </SearchProvider>
    </I18nProvider>
  );
};

FAQContainer.propTypes = {
  faqs: PT.arrayOf(PT.shape({})).isRequired,
  header: PT.shape({}).isRequired,
};

export default FAQContainer;
