import styled, { css } from 'styled-components';
import {
  Box,
  Flex,
  Link,
  Modal,
  H1,
  Card,
  spacing,
  colors,
  Page,
} from '@procore/core-react';

const SmallLogoWrapper = styled(Flex)`
  width: 75px;
  height: 75px;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  padding: 10px;
`;

const MediumLogoWrapper = styled(Flex)`
  width: 100px;
  height: 100px;
  background: ${colors.white};
  margin-right: 38px;
  padding: 0px;
`;

const SmallLogo = styled.img`
  width: 100%;
  height: auto;
`;

const Container = styled(Box)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 45px);
`;

const ContentContainer = styled(Box)`
  max-width: 780px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 ${spacing.lg}px;
  }
`;

const Slide = styled.img`
  object-fit: contain;
`;

const SlickWrapper = styled(Box)`
  .slick-arrow {
    transition: opacity 0.3s;
    opacity: 0;

    &:before {
      color: #1d3243;
    }
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
    }
  }
`;

const arrows = css`
  background: #1d3243;
  width: 50px;
  height: 75px;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: none;
  }

  &:hover {
    background: #1d3243;
  }
`;

const SlickArrowPrev = styled(Box)`
  ${arrows}
  transform: translate(-20px, -50%);
`;

const SlickArrowNext = styled(Box)`
  ${arrows}
  transform: translate(20px, -50%);
`;

const OrangeLink = styled(Link)`
  color: #f47e42;
`;

const ImageModal = styled(Modal)`
  background: transparent;
  box-shadow: none;
  overflow: visible;
  > div {
    overflow: visible;
  }
`;

const ModalImageBody = styled(Box)`
  width: 90vw;
  height: 90vh;
  max-width: none;
  justify-content: center;
  overflow: visible;
  position: relative;
  display: flex;

  > div > div {
    max-height: none;
  }
`;

const ModalImage = styled.img`
  object-fit: contain;
  box-shadow: 0px 0px 32px -4px hsl(200deg 10% 15% / 64%);
  max-width: 100%;
  max-height: 100%;
`;

const CloseImageModalButton = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #000;
  border: 2px solid #fff;

  &:after {
    content: '\\00d7';
    color: #fff;
  }
`;

const HeaderText = styled(H1)`
  font-size: 38px;
  display: block;
  line-height: 44px;
  margin-bottom: ${spacing.sm}px;
`;

const UnstyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;

const DropdownMenu = styled(Card)`
  margin-top: ${spacing.sm}px;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: ${spacing.sm}px;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.28);
`;

const DropdownItem = styled(Box)`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: ${spacing.md}px;
  margin: ${spacing.xs}px 0;
  width: 100%;

  &:hover {
    background-color: ${colors.gray94};
  }
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${({ color }) => color || colors.gray94};
  height: 2px;
  margin: ${spacing.sm}px 0;
`;

const HeaderContainer = styled(Box)`
  max-width: 1344px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 32px;
`;

const PageHeader = styled(Page.Header)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResponsiveFlex = styled(Box)`
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${spacing.lg}px;

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

const TruncatedText = styled(Box)`
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export {
  Container,
  SmallLogo,
  SmallLogoWrapper,
  MediumLogoWrapper,
  HeaderText,
  SlickArrowNext,
  SlickArrowPrev,
  SlickWrapper,
  Slide,
  OrangeLink,
  ImageModal,
  ModalImageBody,
  ModalImage,
  CloseImageModalButton,
  UnstyledLink,
  DropdownMenu,
  DropdownItem,
  Divider,
  ContentContainer,
  HeaderContainer,
  PageHeader,
  ResponsiveFlex,
  TruncatedText,
};
