import styled from 'styled-components';
import {
  Flex,
  H1,
  H2,
  Link,
  Typography, } from '@procore/core-react';
  import { ExternalLink } from '@procore/core-icons';

const ContactContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
`;

const ContactsContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  height: 100%;
`;

const ContactType = styled(Typography)`
  color: var(--grey-concrete-555555, #555);
  font-feature-settings: 'liga' off, 'clig' off;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const DualSectionsContainer = styled(Flex)`
  display: flex;
  align-items: stretch;
  gap: 48px;
  align-self: stretch;
  width: 100%;
  max-width: 684px;
  flex-wrap: wrap;
`;

const EmptyState = styled(Typography)`
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const HelpfulLinksContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const LinksContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`;

const Logo = styled.img`
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  align-self: center;
`;

const SectionContainer = styled(Flex)`
  display: flex;
  align-items: stretch;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

const StyledExternalLink = styled(ExternalLink)`
  width: 16px;
  height: 16px;
`;

const StyledExternalLinkContainer = styled(Flex)`
  display: inline-flex;
  margin-left: 4px;
  vertical-align: middle;
`;

const StyledH1 = styled(H1)`
  color: var(--procore-procore-black-000000, #000);
  font-feature-settings: 'liga' off, 'clig' off;

  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.75px;
`;

const StyledH2 = styled(H2)`
  color: var(--procore-procore-black-000000, #000);
  font-feature-settings: 'liga' off, 'clig' off;

  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.25px;
`;

const StyledLink = styled(Link)`
  color: var(--Blue-blue-45, #1d5cc9);
  font-feature-settings: 'liga' off, 'clig' off;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline !important;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;

const SupportCard = styled(Flex)`
  background: var(--bw-white, #fff);
  display: flex;
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  width: 100%;
  max-width: 780px;
  margin: 48px auto 0 auto;
`;

export {
  ContactContainer,
  ContactsContainer,
  ContactType,
  DualSectionsContainer,
  EmptyState,
  HelpfulLinksContainer,
  LinksContainer,
  Logo,
  SectionContainer,
  StyledExternalLink,
  StyledExternalLinkContainer,
  StyledH1,
  StyledH2,
  StyledLink,
  SupportCard,
}
