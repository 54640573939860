import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { Box, Flex, H1, H2, Link, useI18nContext } from '@procore/core-react';
import { Info } from '@procore/core-icons';
import sanitizeHtml from 'sanitize-html';
import {
  useSanitizedString,
  useSanitizedArray,
} from '@/react/hooks/useSanitizedString';

const FunctionalitySection = ({
  howItWorks,
  features,
  requirements,
  tools,
}) => {
  const i18n = useI18nContext();
  const sanitizedHowItWorks = sanitizeHtml(howItWorks);
  const sanitizedFeatures = useSanitizedArray(features);
  const sanitizedRequirements = useSanitizedArray(requirements);
  return (
    <Flex
      direction="column"
      margin="xl 0"
      data-testid="functionality-section"
      justifyContent='flex-start'
    >
      <Box margin="lg none">
        <H1>{i18n.t('appDetails.titles.functionality')}</H1>
      </Box>

      <Box margin="sm none">
        <H2>{i18n.t('appDetails.titles.howItWorks')}</H2>
      </Box>
      <Box
        margin="sm none"
        className="display-white-space"
        dangerouslySetInnerHTML={{ __html: sanitizedHowItWorks }}
      />

      {features && (
        <>
          <Box margin="sm none">
            <H2>{i18n.t('appDetails.titles.features')}</H2>
          </Box>
          <ul>
            {sanitizedFeatures.map((feature) => (
              <Box
                margin="xs none"
                key={`feature-${feature}`}
                className="display-white-space"
              >
                <li dangerouslySetInnerHTML={{ __html: feature }} />
              </Box>
            ))}
          </ul>
        </>
      )}

      {requirements && (
        <>
          <Box margin="sm none">
            <H2>{i18n.t('appDetails.titles.requirements')}</H2>
          </Box>
          <ul>
            {sanitizedRequirements.map((requirement) => (
              <Box
                margin="xs none"
                key={`requirement-${requirement}`}
                className="display-white-space"
              >
                <li dangerouslySetInnerHTML={{ __html: requirement }} />
              </Box>
            ))}
          </ul>
        </>
      )}

      <Box margin="sm none">
        <H2>{i18n.t('appDetails.titles.tools')}</H2>
      </Box>

      {tools?.length > 0 ? (
        <ul>
          {tools.map((tool) => (
            <Box margin="xs none" key={`tool-${tool}`}>
              <li>{tool}</li>
            </Box>
          ))}
        </ul>
      ) : (
        <Flex direction="row" margin="sm none" alignItems="center">
          <Info />
          <Box margin="sm">
            {i18n.t('appDetails.toolsInformation')}
            <Link href="#support-section">
              {i18n.t('appDetails.toolsInformationLink')}
            </Link>
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

FunctionalitySection.propTypes = {
  howItWorks: PT.string.isRequired,
  features: PT.arrayOf(PT.string),
  requirements: PT.arrayOf(PT.string),
  tools: PT.arrayOf(PT.string),
};

export default FunctionalitySection;
