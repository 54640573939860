import React from 'react';
import PT from 'prop-types';
import { useI18nContext } from '@procore/core-react';
import {
  ContactContainer,
  ContactsContainer,
  ContactType,
  DualSectionsContainer,
  EmptyState,
  HelpfulLinksContainer,
  LinksContainer,
  Logo,
  SectionContainer,
  StyledExternalLink,
  StyledExternalLinkContainer,
  StyledH1,
  StyledH2,
  StyledLink,
  SupportCard,
} from './Support.styles';

const SupportSection = ({ name, website, contactUsEmail, helpfulLinks }) => {
  const i18n = useI18nContext();

  return (
    <SupportCard data-testid="support-section" id="support-section">
      <StyledH1>{i18n.t('appDetails.titles.support')}</StyledH1>
      <DualSectionsContainer>
        <SectionContainer>
          <Logo src={ imagePath('helpful-links.svg') } />
          <LinksContainer>
            <StyledH2>{i18n.t('appDetails.titles.helpfulLinks')}</StyledH2>
            <HelpfulLinksContainer>
              {
                helpfulLinks.length ? helpfulLinks.map(({ url, label }) => (
                  <StyledLink key={label} href={url} target="_blank" rel="noopener noreferrer">
                    {label}
                    <StyledExternalLinkContainer>
                      <StyledExternalLink />
                    </StyledExternalLinkContainer>
                  </StyledLink>
                )) :
                  <EmptyState>{i18n.t('appDetails.noHelpfulLinks')}</EmptyState>
              }

            </HelpfulLinksContainer>
          </LinksContainer>
        </SectionContainer>
        <SectionContainer>
          <Logo src={ imagePath('contact.svg') } />
          <ContactsContainer>
            <StyledH2>{i18n.t('appDetails.titles.contact')}</StyledH2>
            {contactUsEmail && <ContactContainer>
              <ContactType>Email</ContactType>
              <StyledLink href={`mailto:${contactUsEmail}`}>
                {contactUsEmail}
              </StyledLink>
            </ContactContainer>}
            {website && name && <ContactContainer>
              <ContactType>{i18n.t('appDetails.labels.website')}</ContactType>
              <StyledLink href={website} target="_blank" rel="noopener noreferrer">
                {name}
                <StyledExternalLinkContainer>
                  <StyledExternalLink />
                </StyledExternalLinkContainer>
              </StyledLink>
            </ContactContainer>}
          </ContactsContainer>
        </SectionContainer>
      </DualSectionsContainer>
    </SupportCard>
  );
};

SupportSection.propTypes = {
  name: PT.string.isRequired,
  website: PT.string.isRequired,
  contactUsEmail: PT.string.isRequired,
  helpfulLinks: PT.arrayOf(
    PT.shape({
      url: PT.string.isRequired,
      label: PT.string.isRequired,
    })
  ),
};

export default SupportSection;
