/**
 * Private Helpers
 */
const _getSharedBaseHeaders = () => ({
  'X-CSRF-TOKEN': document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content'),
});

export const POST_HEADERS = {
  'Content-Type': 'application/json',
};

export const getHeaders = (headers = {}) => ({
  ..._getSharedBaseHeaders(),
  ...headers,
});
