import React from 'react';
import PT from 'prop-types';
import { Box, colors, Typography, useI18nContext } from '@procore/core-react';
import styled from 'styled-components';

const Container = styled(Box)`
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
`;

const FAQBox = styled(Box)`
  background-color: ${colors.white};
  border-radius: 8px;

  a {
    color: ${colors.orange50};
    text-decoration: underline !important;

    &:hover {
      color: ${colors.orange40};
    }
  }
`;

const FAQ = ({ faqs }) => {
  const i18n = useI18nContext();
  return (
    <Container display="flex-column" gap="lg" padding="xxl lg">
      <Typography intent="h1">{i18n.t('appDetails.titles.faqs')}</Typography>
      <Box display="flex-column" gap="lg">
        {faqs &&
          faqs.length !== 0 &&
          faqs.map((faq) => (
            <FAQBox
              key={faq.id}
              padding="lg xxl"
              display="flex-column"
              gap="sm"
            >
              <Typography intent="h2">{faq.question}</Typography>
              <Typography
                intent="body"
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </FAQBox>
          ))}
      </Box>
    </Container>
  );
};

FAQ.propTypes = {
  faqs: PT.arrayOf(PT.shape({})).isRequired,
};

export default FAQ;
