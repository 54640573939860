import React, { useEffect, useState } from 'react';
import {
  Modal,
  Link,
  useI18nContext,
  H3,
  Flex,
  Typography,
  spacing,
  Button,
} from '@procore/core-react';
import styled from 'styled-components';
import {
  UnderlineModalHeader,
  MinWidthModal,
  MinWidthModalBody,
  LargeSpacingDivider,
} from '@/react/styles/v2';
import { UnderlineLink } from '../../../../../styles/v2';

const CountryList = styled.ul`
  padding-left: ${spacing.lg}px;
  min-width: 200px;
  width: 100%;
`;

export const RegionModal = ({ regions, countries }) => {
  const i18n = useI18nContext();
  const [isRegionsOpen, setIsRegionsOpen] = useState(false);

  // split the countries for a region into max 3 columns of length 10 or less
  const [mappedCountries, setMappedCountries] = useState({});
  useEffect(() => {
    const newMappedCountries = {};
    Object.keys(countries).forEach((region) => {
      const columns = [];
      let column = [];
      const maxLength = Math.ceil(countries[region].length / 3);
      countries[region].forEach((country) => {
        if (column.length >= maxLength) {
          columns.push(column);
          column = [];
        }
        column.push(country);
      });
      columns.push(column);
      newMappedCountries[region] = columns;
    });
    setMappedCountries(newMappedCountries);
  }, [countries]);

  return (
    <>
      <UnderlineLink>
        <Link onClick={() => setIsRegionsOpen(!isRegionsOpen)}>
          {i18n.t('appDetails.sideBar.regionModal.trigger')}
        </Link>
      </UnderlineLink>
      <MinWidthModal
        onClickOverlay={() => setIsRegionsOpen(false)}
        open={isRegionsOpen}
      >
        <UnderlineModalHeader>
          {i18n.t('appDetails.sideBar.regionModal.title')}
        </UnderlineModalHeader>
        <MinWidthModalBody>
          {regions &&
            regions.length > 0 &&
            regions.map((region, regionIndex) => {
              return (
                <Flex key={region} direction="column">
                  <H3>{`${region}${
                    countries[region]?.length > 0
                      ? ` (${countries[region].length})`
                      : ''
                  }`}</H3>
                  {mappedCountries[region] && (
                    <Flex direction="row" grow="1" alignSelf="stretch">
                      {mappedCountries[region].map((column, index) => {
                        return (
                          <Flex
                            direction="column"
                            key={`${region}-${index}`}
                            grow="1"
                            alignSelf="stretch"
                          >
                            <CountryList>
                              {column.map((country) => {
                                return (
                                  <li key={country}>
                                    <Typography intent="body">
                                      {country}
                                    </Typography>
                                  </li>
                                );
                              })}
                            </CountryList>
                          </Flex>
                        );
                      })}
                    </Flex>
                  )}
                  {regionIndex !== regions.length - 1 && (
                    <LargeSpacingDivider />
                  )}
                </Flex>
              );
            })}
        </MinWidthModalBody>
        <Modal.Footer>
          <Modal.FooterButtons>
            <Button onClick={() => setIsRegionsOpen(false)}>
              {i18n.t('appDetails.sideBar.returnToListing')}
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </MinWidthModal>
    </>
  );
};
