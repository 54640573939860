import React from 'react';
import cx from 'classnames';
import qs from 'qs';
import Dropdown from '@/react/shared/Dropdown';
import SearchInput from '@/react/shared/SearchInput';

const I18n = {
  cont: 'Continue',
  select_a_company: 'Select a Company'
};

let companyCounter = 0;

class CompanyPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: null,
      dropdownOpen: false,
      searchValue: '',
    };

    this.handleSearch = this.handleSearch.bind(this);
  }

  setCompanyId(companyId) {
    this.setState({ companyId });
  }

  handleSearch(searchValue) {
    this.setState({searchValue});
  }

  renderCompanies() {
    const { companies } = this.props;
    const { searchValue } = this.state;
    return companies.filter(company => (company.name.toLowerCase().indexOf(searchValue.toLowerCase()) === 0)).map((company) => (
      <a
        onClick={ () => this.setCompanyId(company.id) }
        className='dropdown-item'
        key={`${company.name}-${company.id}-${companyCounter++}`}
      >
        {company.name}
      </a>
    ))
  }

  render() {
    const { linkTo, companies } = this.props;
    const { companyId, dropdownOpen } = this.state;
    const disabled = !companyId;

    const selectedCompany = companies.find((c) => (c.id == companyId));

    let continuePath;
    if (selectedCompany) {
      const params = {
        'company_id': selectedCompany.id,
        'company_name': selectedCompany.name,
        'company_root': selectedCompany.procore_root,
      };

      continuePath = `${linkTo}?${qs.stringify(params)}`;
    }

    return (
      <>
        <Dropdown
          className='company-picker'
          closeOnSelect
          dropdownDirection='south'
          dropdownTarget={
            <span className={cx('dropdown-default-target','company-select-dropdown')}>
              <div className='dropdown-text'>
                {
                  selectedCompany ?
                    selectedCompany.name :
                    I18n.select_a_company
                }
              </div>
              <div className='dropdown-icon fa fa-chevron-down'/>
            </span>
          }
        >
          <SearchInput
            onSearchChange={this.handleSearch}
          />
          {this.renderCompanies()}
        </Dropdown>
        <a
          href={continuePath}
          className={cx('button-continue', 'primaryButton', { disabled })}
        >
          {I18n.cont}
        </a>
      </>
    );
  }
}

export default CompanyPicker;
