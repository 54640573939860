import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

class Popover extends React.Component {
  render() {
    const {
      className,
      popoverTarget,
      children,
      position,
      content
    } = this.props;

    return (
      <div className={cx(styles.popoverWrapper, className)}>
        <div className={cx(styles.popoverTarget, "fa fa-question-circle")} />

        <div className={cx(styles.popoverContent, styles[`${position}PopoverContent`])}>
          {children}
        </div>
      </div>
    );
  }
}

Popover.defaultProps = {
  position: 'bottom'
}

export default Popover;
