import React, { useState, useEffect } from 'react';

const SearchInput = (
  {onSearchChange}
) => {
  const [searchValue,setSearchValue] = useState('');

  useEffect(() => {
    onSearchChange(searchValue);
  }, [searchValue]);

  const clearSearch = () => {
    setSearchValue('');
  };

  return (
    <div
      className='search-component__wrapper'
      // Stop propagation of click events on this component
      // to prevent selection if used in dropdown
      onClick={e => e.stopPropagation()}
    >
      <input
        className='search-component__input'
        type='text'
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder='Search'
      />
      <button
        className='search-component--button__clear'
        onClick={clearSearch}
      />
    </div>
  )
};

export default SearchInput;
