import 'core-js/stable';
import 'regenerator-runtime/runtime';

// NodeList.forEach
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
if ( !NodeList.prototype.forEach ) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// ChildNode.remove
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
(function (arr) {
  arr.forEach(function (item) {
    item.remove = item.remove || function () {
      this.parentNode.removeChild(this);
    };
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
