import React, { useState } from 'react';
import {
  Link,
  Modal,
  useI18nContext,
  Button,
  H3,
  Flex,
  P,
  Typography,
  spacing,
} from '@procore/core-react';
import {
  UnderlineModalHeader,
  MinWidthModal,
  MinWidthModalBody,
} from '@/react/styles/v2';
import styled from 'styled-components';
import { UnderlineLink } from '@/react/styles/v2';

// non-standard color in design
const DividerSection = styled(Flex)`
  border-bottom: 1px solid #a3a4a6;
  padding-top: ${spacing.lg}px;
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    padding-top: ${spacing.lg}px;
  }
`;

export const VersionHistoryModal = ({ versionHistory }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const i18n = useI18nContext();
  return (
    <>
      <UnderlineLink>
        <Link onClick={() => setIsModalOpen(true)}>
          {i18n.t('appDetails.sideBar.historyModal.trigger')}
        </Link>
      </UnderlineLink>
      <MinWidthModal
        open={isModalOpen}
        onClickOverlay={() => setIsModalOpen(false)}
        style={{ minWidth: '40%' }}
      >
        <UnderlineModalHeader>
          {i18n.t('appDetails.sideBar.historyModal.title')}
        </UnderlineModalHeader>
        <MinWidthModalBody>
          {versionHistory.map((version) => (
            <DividerSection key={version.semantic_version} direction="column" w>
              <H3>{version.semantic_version}</H3>
              <Typography intent="small" color="gray-30">
                {version.created_at}
              </Typography>
              <P>
                {version.changelog ||
                  i18n.t('appDetails.sideBar.historyModal.noChangelog')}
              </P>
            </DividerSection>
          ))}
        </MinWidthModalBody>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setIsModalOpen(false)}>
            {i18n.t('appDetails.sideBar.returnToListing')}
          </Button>
        </Modal.Footer>
      </MinWidthModal>
    </>
  );
};
