import React, { useState } from 'react';
import PT from 'prop-types';
import {
  Flex,
  H1,
  H2,
  Link,
  Typography,
  colors,
  useI18nContext,
} from '@procore/core-react';
import {
  Building,
  Check,
  ChevronDown,
  ChevronUp,
  Clear,
  Location,
  RibbonPerson,
  ShieldStar,
} from '@procore/core-icons';
import styled from 'styled-components';
import { useSanitizedString } from '@/react/hooks/useSanitizedString';

const maxContentWidth = '780px';

const Logo = styled.img`
  height: 96px;
  width: 96px;
`;

const Label = styled(Typography)`
  color: ${colors.gray30};
  margin: 12px 0px 0px 0px;
`;

const BreakingLine = styled.hr`
  border: 0;
  border-top: 1px solid ${colors.gray70};
  margin: 24px 0px;
  width: 100%;
`;

const ConditionalLine = ({ condition, affirmative, negative }) => {
  return (
    <Flex direction="row" alignItems="start" margin="lg none">
      {condition ? (
        <Check size="sm" color={colors.green30} />
      ) : (
        <Clear size="sm" color={colors.red30} />
      )}
      <Flex margin="none xs">
        <Typography intent="body" color={colors.gray30}>
          <Typography intent="body" color={colors.gray30}>
            {condition ? affirmative : negative}
          </Typography>
        </Typography>
      </Flex>
    </Flex>
  );
};

ConditionalLine.propTypes = {
  condition: PT.bool.isRequired,
  affirmative: PT.node.isRequired,
  negative: PT.node.isRequired,
};

const CollapsibleSection = ({
  title,
  children,
  openSection,
  setOpenSection,
  sectionId,
}) => {
  const open = openSection === sectionId;

  return (
    <Flex
      margin="lg none none none"
      direction="column"
      style={{ width: '100%' }}
      onClick={() => setOpenSection(openSection === sectionId ? '' : sectionId)}
    >
      <Flex
        direction="row"
        justifyContent="space-between"
        style={{ width: '100%' }}
      >
        <H2>{title}</H2>
        {open ? <ChevronUp /> : <ChevronDown />}
      </Flex>
      <BreakingLine />
      {open && children}
    </Flex>
  );
};

CollapsibleSection.propTypes = {
  title: PT.string,
  children: PT.node.isRequired,
  openSection: PT.string.isRequired,
  setOpenSection: PT.func.isRequired,
  sectionId: PT.string.isRequired,
};

const SecuritySection = ({ app, questionnaire }) => {
  const i18n = useI18nContext();
  const [openSection, setOpenSection] = useState('');

  const sanitizedAbout = useSanitizedString(app.about);

  if (!app.security_questionnaire_visible || !questionnaire) {
    return (
      <Flex padding="xxl none" direction="column">
        <H1>{i18n.t('appDetails.titles.security')}</H1>
        <Flex
          padding="xl"
          margin="xl auto"
          style={{ backgroundColor: colors.gray96, width: '100%' }}
        >
          <Flex direction="row" alignItems="flex-start">
            <Logo src={ imagePath('document.png') } />
            <Flex direction="column" margin="none none none xl">
              <Flex margin="none none md none">
                <H2>{i18n.t('appDetails.security.informationNotProvided')}</H2>
              </Flex>
              <Typography intent="body">
                {i18n.t('appDetails.security.informationNotProvidedDetails')}
              </Typography>
            </Flex>
          </Flex>
        </Flex>
        <H2>{i18n.t('appDetails.security.aboutApp', { app: app.built_by })}</H2>
        <Flex margin="md none none none" className="display-white-space">
          <Typography dangerouslySetInnerHTML={{ __html: sanitizedAbout }} />
        </Flex>
      </Flex>
    );
  }

  const securityStandards = [
    ...questionnaire.security_standards.map(
      (standard) => standard.standard_name
    ),
    ...(questionnaire.other_security_standards === ''
      ? []
      : [questionnaire.other_security_standards]),
  ];

  return (
    <Flex
      direction="column"
      style={{ maxWidth: maxContentWidth }}
      padding="xl none"
      margin="auto"
    >
      <H1>{i18n.t('appDetails.titles.security')}</H1>

      <Flex
        direction="column"
        padding="xl"
        margin="xxl none"
        style={{
          backgroundColor: colors.blue94,
          width: '100%',
        }}
      >
        <Flex direction="row" alignItems="center" margin="none none md none">
          <ShieldStar />
          <Flex margin="none none none md">
            <H2>{i18n.t('appDetails.security.review')}</H2>
          </Flex>
        </Flex>
        <Typography intent="body">
          {`${i18n.t('appDetails.security.reviewDetails')} `}
          <Link
            target="_blank"
            href="https://support.procore.com/faq/what-is-the-security-and-trust-self-certified-badge"
          >
            {i18n.t('appDetails.partnerSecurityBadgeLink')}
          </Link>
        </Typography>
      </Flex>

      <Flex direction="column" margin="none none lg none">
        <H2>{i18n.t('appDetails.security.aboutApp', { app: app.built_by })}</H2>
        <Flex margin="md none none none" className="display-white-space">
          <Typography dangerouslySetInnerHTML={{ __html: sanitizedAbout }} />
        </Flex>
      </Flex>

      <Flex direction="column">
        <Label intent="label" color={colors.gray40}>
          {i18n.t('appDetails.security.legalName')}
        </Label>

        <Flex direction="row" alignItems="center">
          <RibbonPerson size="sm" color={colors.gray40} />
          <Flex margin="xs">
            <Typography intent="body" color={colors.gray40}>
              {questionnaire.company_legal_name}
            </Typography>
          </Flex>
        </Flex>
        <Label intent="label" color={colors.gray40}>
          {i18n.t('appDetails.security.headquarters')}
        </Label>
        <Flex direction="row" alignItems="center">
          <Building size="sm" color={colors.gray40} />
          <Flex margin="xs">
            <Typography intent="body" color={colors.gray40}>
              {questionnaire.headquarters_country}
            </Typography>
          </Flex>
        </Flex>
      </Flex>

      <Flex style={{ width: '100%' }} direction="column">
        <CollapsibleSection
          title={i18n.t('appDetails.security.privacy')}
          openSection={openSection}
          setOpenSection={setOpenSection}
          sectionId="privacy"
        >
          <Typography weight="bold">
            {i18n.t('appDetails.security.dataProcessingLocations')}
          </Typography>
          <Label intent="label">
            {i18n.t('appDetails.security.countries')}
          </Label>
          <Flex direction="row" alignItems="center">
            <Location size="sm" color={colors.gray30} />
            <Flex margin="xs">
              <Typography intent="body" color={colors.gray30}>
                {questionnaire.data_processing_locations.join(', ')}
              </Typography>
            </Flex>
          </Flex>
          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.subprocessors')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_subprocessors}
            affirmative={questionnaire.subprocessors_details}
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.subprocessorsPersonalData')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_sensitive_data}
            affirmative={questionnaire.sensitive_data_handling_details}
            negative={i18n.t('appDetails.security.no')}
          />
        </CollapsibleSection>

        <CollapsibleSection
          title={i18n.t('appDetails.security.informationSecurity')}
          openSection={openSection}
          setOpenSection={setOpenSection}
          sectionId="information-security"
        >
          <Typography weight="bold">
            {i18n.t('appDetails.security.dedicatedFunction')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_security_contact}
            affirmative={
              <Typography intent="body" color={colors.gray30}>
                {i18n.t('appDetails.security.contactMessage')}
                <Link href={`mailto:${questionnaire.security_email}`}>
                  {questionnaire.security_email}
                </Link>
              </Typography>
            }
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.audits')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_security_audits}
            affirmative={i18n.t('appDetails.security.hasAudits')}
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.technicalMeasures')}
          </Typography>
          <Label intent="label" color={colors.gray40}>
            {i18n.t('appDetails.security.securityTeam')}
          </Label>
          <ConditionalLine
            condition={questionnaire.has_security_contact}
            affirmative={
              <Typography intent="body" color={colors.gray30}>
                <Flex direction="row">
                  {`${i18n.t('appDetails.security.yes')}. `}
                  <Flex margin="none xs">
                    <Link href={`mailto:${questionnaire.security_email}`}>
                      {questionnaire.security_email}
                    </Link>
                  </Flex>
                </Flex>
              </Typography>
            }
            negative={i18n.t('appDetails.security.no')}
          />

          <Label intent="label" color={colors.gray40}>
            {i18n.t('appDetails.security.bugBountyProgram')}
          </Label>
          <ConditionalLine
            condition={questionnaire.has_bug_bounty_program}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <Label intent="label" color={colors.gray40}>
            {i18n.t('appDetails.security.vulnerabilityProgram')}
          </Label>
          <ConditionalLine
            condition={questionnaire.has_disclosure_program}
            affirmative={
              <Typography intent="body" color={colors.gray30}>
                <Flex direction="row">
                  {`${i18n.t('appDetails.security.yes')}. `}
                  <Flex margin="none xs">
                    <Link href={questionnaire.disclosure_program_url}>
                      {questionnaire.disclosure_program_url}
                    </Link>
                  </Flex>
                </Flex>
              </Typography>
            }
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.accredidations')}
          </Typography>
          <Label intent="label" color={colors.gray40}>
            {i18n.t('appDetails.security.standards')}
          </Label>
          <ConditionalLine
            condition={questionnaire.has_security_standards}
            affirmative={
              <Typography intent="body" color={colors.gray30}>
                {`${i18n.t('appDetails.security.yes')}. `}
                {securityStandards.join(', ')}
              </Typography>
            }
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.penTesting')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_penetration_testing}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.dataLoss')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.can_notify_procore}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.vulnerabilityDisclosure')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_disclosure_program}
            affirmative={
              <Flex direction="row">
                {`${i18n.t('appDetails.security.yes')}. `}
                <Flex margin="none xs">
                  <Link href={questionnaire.disclosure_program_url}>
                    {i18n.t(
                      'appDetails.security.vulnerabilityDisclosureProgram'
                    )}
                  </Link>
                </Flex>
              </Flex>
            }
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.bugBounty')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_bug_bounty_program}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />
        </CollapsibleSection>

        <CollapsibleSection
          title={i18n.t('appDetails.security.applicationSecurity')}
          openSection={openSection}
          setOpenSection={setOpenSection}
          sectionId="application-security"
        >
          <Typography weight="bold">
            {i18n.t('appDetails.security.aesEncryption')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_data_encryption_at_rest}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.tlsEncryption')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_data_encryption_in_transit}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <Typography weight="bold">
            {i18n.t('appDetails.security.ssoSupported')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_sso_support}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <BreakingLine />

          <Typography weight="bold">
            {i18n.t('appDetails.security.samlSupported')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_saml_support}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <Typography weight="bold">
            {i18n.t('appDetails.security.mfaRequired')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_mfa_support}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />

          <Typography weight="bold">
            {i18n.t('appDetails.security.pfasSupported')}
          </Typography>
          <ConditionalLine
            condition={questionnaire.has_fas_support}
            affirmative={i18n.t('appDetails.security.yes')}
            negative={i18n.t('appDetails.security.no')}
          />
        </CollapsibleSection>
      </Flex>
    </Flex>
  );
};

SecuritySection.propTypes = {
  app: PT.shape({
    about: PT.string,
    built_by: PT.string,
    security_questionnaire_visible: PT.bool,
  }),
  questionnaire: PT.shape({
    can_notify_procore: PT.bool.isRequired,
    company_legal_name: PT.string.isRequired,
    data_processing_locations: PT.arrayOf(PT.string),
    disclosure_program_url: PT.string.isRequired,
    has_bug_bounty_program: PT.bool.isRequired,
    has_data_encryption_at_rest: PT.bool.isRequired,
    has_data_encryption_in_transit: PT.bool.isRequired,
    has_disclosure_program: PT.bool.isRequired,
    has_fas_support: PT.bool.isRequired,
    has_mfa_support: PT.bool.isRequired,
    has_penetration_testing: PT.bool.isRequired,
    has_saml_support: PT.bool.isRequired,
    has_security_audits: PT.bool.isRequired,
    has_security_contact: PT.bool.isRequired,
    has_security_standards: PT.bool.isRequired,
    has_sensitive_data: PT.bool.isRequired,
    has_sso_support: PT.bool.isRequired,
    has_subprocessors: PT.bool.isRequired,
    headquarters_country: PT.string.isRequired,
    other_security_standards: PT.string.isRequired,
    security_email: PT.string.isRequired,
    security_standards: PT.arrayOf(
      PT.shape({
        variant: PT.string,
        standard_name: PT.string,
      })
    ),
    sensitive_data_handling_details: PT.string.isRequired,
    subprocessors_details: PT.string.isRequired,
  }),
};

export default SecuritySection;
