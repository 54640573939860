import { createI18njs } from '@procore/core-i18n-js';

export default createI18njs({
  locale: 'en',
  translations: {
    en: {
      navbar: {
        app: 'Build an App',
        community: 'Community',
        partner: 'Become a Partner',
        idea: 'Post an Idea',
        webinars: 'Webinars',
        faq: 'FAQ',
        companySwitch: 'Change Company',
        logout: 'Logout',
        supportCenter: 'Support Center',
      },
      hero: {
        tagline: 'Explore Integrations for Procore',
      },
      header: {
        builtBy: 'Built by',
        builtByOverlay: '%{value} developed this app.',
        supportedBy: 'Supported by',
        supportedByOverlay: '%{value} provides support for this app.',
        installs: 'Installs',
        installsOverlay:
          'This app is installed by at least %{value} companies.',
        securityBadge: 'Security & Trust',
        beta: 'Beta',
      },
      securityBadge: {
        title: 'Security & Trust Partner Self-Certified',
        partnerAnswered:
          'The following Partner has supplied answers to a set of standard questions, self-certifying their adherence to specific security requirements.',
        link: 'View the Partner’s answers here.',
        responsibility:
          'The Partner is solely responsible for the accuracy of the information provided.',
        learnMore: 'Learn more about the badge here.',
      },
      appDetails: {
        loginToInstall: 'Login to Install',
        mustBeAdmin: 'You must be a company admin to install an app',
        installApp: 'Install App',
        noHelpfulLinks: 'The partner did not provide any helpful links.',
        noRegions: 'The partner did not provide this information.',
        partnerSecurityReview:
          'Partner Security Review | Self-Certified Responses',
        partnerSecurityDescription:
          'The following partner has supplied answers to a set of standard questions, self-certifying their adherence to specific security requirements.',
        partnerSecurityLink: "View the partner's answers here.",
        partnerSecurityBadge:
          'The partner is solely responsible for the accuracy of the information provided.',
        partnerSecurityBadgeLink: 'Learn more about the badge here.',
        pricing: {
          title: 'Pricing',
          titleNoPricing: 'Pricing Information Not Available',
          soldBy: 'Sold By ',
          website: 'Visit Pricing Website',
          faqs: 'FAQs',
          description:
            "Our application offers flexible pricing plans to suit the needs of every user. Whether you are an individual, a small business, or a large enterprise, we have a plan that fits your budget and requirements. Each plan includes a variety of features designed to help you get the most out of our application. Explore our pricing options and choose the one that's right for you.",
          descriptionNoPricing:
            'The pricing details for this app have not been specified. ',
          contactPartner: 'Contact the partner for more information.',
          faqsHeaderPart1:
            'These standard questions are answered by our partners to ensure transparency. Procore isn’t responsible for the pricing details, please ',
          faqsHeaderPart2: 'contact the partner',
          faqsHeaderPart3: ' for more detailed information.',
          subscriptionRequired: {
            description:
              'Does the app require an additional subscription to another system?',
            yes: 'Yes, an additional subscription is required.',
            no: 'No, an additional subscription is not required.',
          },
          thirdPartyConnectorRequired: {
            description:
              'Is there a third-party connector needed for the integration, or is it included in the subscription?',
            yes: 'Yes, a third-party connector is needed for the integration.',
            no: 'No, a third-party connector is not needed for the integration.',
          },
          freeTrialOffered: {
            description: 'Does the app offer a free trial?',
            yes: 'Yes, a free trial is available.',
            no: 'No, a free trial is not available.',
          },
        },
        security: {
          informationNotProvided:
            'Security & Compliance Information Not Provided',
          informationNotProvidedDetails:
            'The Partner did not provide the Security & Compliance information.',
          review: 'Partner Security Review | Self-Certified Responses',
          reviewDetails:
            'This partner has provided answers to a set of standard questions, self-certifying their adherence to specific security requirements. The Partner is solely responsible for the accuracy of the information provided.',
          aboutApp: 'About %{app}',
          legalName: 'COMPANY LEGAL NAME',
          headquarters: 'HEADQUARTERS LOCATION',
          privacy: 'Privacy & Data Governance',
          informationSecurity: 'Information Security Practices',
          applicationSecurity: 'Application Security / Features',
          dataProcessingLocations: 'Data Processing Locations',
          countries: 'COUNTRIES',
          subprocessors:
            'Does your company engage subprocessors / vendors (e.g. cloud hosting providers, backup vendors, service providers, etc.) (“Subprocessors”) to process or store customer data?',
          subprocessorsPersonalData:
            'Do you or your subprocessors process or store sensitive personal data as part of the services/products you provide?',
          dedicatedFunction:
            'Does your company have a dedicated security function (e.g., security employees and management)?',
          audits:
            'Does your company undertake audits or other reviews to ensure security controls are implemented and operating effectively?',
          hasAudits:
            'Yes. Regular internal audits and external reviews are conducted to ensure compliance.',
          accredidations:
            'Are you accredited to any relevant security standards?',
          penTesting:
            'Do you undertake penetration testing (or similar technical security testing, code review or vulnerability assessment); and are you able to provide copies of results/findings?',
          dataLoss:
            'Do you have mechanisms to notify Procore and customers in case of a security breach or data loss?',
          vulnerabilityDisclosure:
            'Do you have a vulnerability disclosure program?',
          vulnerabilityDisclosureProgram: 'Vulnerability Disclosure Program',
          disclosureUrl: 'URL to Public Facing Program',
          bugBounty: 'Do you have a bug bounty program?',
          aesEncryption:
            'Is customer data encrypted at rest a minimum standard of AES-256 or equivalent?',
          tlsEncryption:
            'Is customer data encrypted in transit a minimum standard of TLS 1.2?',
          ssoSupported: 'Does your application support Single Sign On (SSO)?',
          samlSupported:
            'Does your application support Security Assertion Markup Language (SAML)?',
          mfaRequired:
            'Is multifactor authentication (MFA) required for back-end system access used to process customer data?',
          pfasSupported:
            'Does your integration support authenticated requests to Procore File Access Service (FAS)?',
          contactMessage: 'Yes. For security issues, contact ',
          no: 'No',
          yes: 'Yes',
          technicalMeasures:
            'What technical measures does your company implement to protect customer personal data?',
          securityTeam: 'SECURITY TEAM',
          bugBountyProgram: 'BUG BOUNTY PROGRAM',
          vulnerabilityProgram: 'SECURITY VULNERABILITY PROGRAM',
          standards: 'STANDARDS',
        },
        toolsInformation:
          'Tools information not declared by the Partner. This app may not require specific Procore tools for its proper functionality. If you have any questions, ',
        toolsInformationLink:
          'please contact the partner for more information.',
        labels: {
          email: 'EMAIL',
          website: 'WEBSITE',
        },
        titles: {
          security: 'Security & Compliance',
          category: 'Categories',
          community: 'Ask the Community',
          contact: 'Contact',
          email: 'Email',
          features: 'Features',
          functionality: 'Functionality',
          helpfulLinks: 'Helpful Links',
          howItWorks: 'How it Works',
          overview: 'Overview',
          requirements: 'Integration Requirements',
          summary: 'Integration Summary',
          support: 'Support',
          tools: 'Required Procore Tools',
          type: 'Type',
          website: 'Website',
          embeddedType: 'Embedded Display Type',
          appType: 'App Type',
          availableIn: 'Available in',
          version: 'Version',
          joinCommunity: {
            join: 'Join the',
            integrations: 'integrations discussion',
            inThe: 'in the',
            community: 'Procore Community',
          },
          displayType: 'Embedded Display Type',
          faqs: 'FAQs',
        },
        sideBar: {
          embeddedTypePopover: {
            fullscreen:
              'Occupies the entire Procore window, providing an immersive and comprehensive interface for the app.',
            sidepanel:
              'Displays the app in a fixed-width panel on the right side of the Procore interface, ideal for contextual tools and quick access.',
            both: 'This app offers the flexibility in how it is display within Procore, either occupying the entire window for an immersive experience and in a fixed-width side panel for quick, contextual access.',
          },
          embeddedType: 'Learn more about display types here.',
          displayType: {
            iframe: 'Full Screen',
            fullscreen: 'Full Screen',
            sidepanel: 'Side Panel',
          },
          appType: {
            embedded: 'Embedded',
            dataConnector: 'Data Connector',
            dataConnectorAndEmbedded: 'Data Connector and Embedded',
          },
          appTypePopover:
            'This app utilizes Procore APIs to offer seamless data connection integration and supports an immersive experience through an embedded interface within Procore, providing streamlined workflows and comprehensive data exchange capabilities.',
          appTypeLink: 'Learn more about app types here.',
          returnToListing: 'Return to Listing',
          regionModal: {
            title: 'Country & Region Availability',
            trigger: 'View Available Regions',
          },
          historyModal: {
            title: 'Version History',
            trigger: 'View App History',
            noChangelog: 'No description provided',
          },
        },
      },
      contactUs: {
        formDescription:
          'Please fill out the form below. A representative will be in touch with you shortly with more information on pricing and how to get started.',
        thanksForVisiting:
          'Thanks for visiting our App Marketplace and requesting information on',
        submitted:
          'We have received your request and a representative will contact you shortly.',
        issues:
          'If you are having issues viewing the form, please disable your Adblock.',
        mustLogIn: 'Log in to contact the Partner',
      },
      labels: {
        myAccount: 'My Account',
        login: 'Login',
        searchInput: 'Search for Apps',
        searchLoading: 'Searching...',
        noResults: 'No results found',
        viewAll: 'View All Results',
        learnHow: 'Learn how',
        contactUs: 'Contact Us',
        installed: 'Installed',
        thankYou: 'Thank You',
      },
      ariaLabels: {
        supportButton: 'Open Support Menu',
        account: 'My Account',
      },
      global: {
        appMarketplace: 'App Marketplace',
      },
    },
  },
});
