import { useEffect, useRef, useState, useCallback } from 'react';

/**
 * Custom hook to check if an element is within the viewport.
 *
 * @param {number} offset - Number of pixels for offset from the top.
 * @param {boolean} initialVisibility - Initial visibility state, default is true.
 * @returns {[boolean, React.RefObject]} isVisible and ref to attach to the element.
 */
export const useVisibility = (offset = 0, initialVisibility = true) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);
  const elementRef = useRef(null);

  // Avoid re-creating it on each render
  const checkVisibility = useCallback(() => {
    if (!elementRef.current) {
      return;
    }

    const { top, bottom } = elementRef.current.getBoundingClientRect();
    const withinViewport =
      top + offset >= 0 && bottom - offset <= window.innerHeight;
    setIsVisible(withinViewport);
  }, [offset]);

  // Setup event listeners for scroll and resize
  useEffect(() => {
    checkVisibility(); // Initial check
    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);

    // Cleanup event listeners
    return () => {
      window.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('resize', checkVisibility);
    };
  }, [checkVisibility]);

  return [isVisible, elementRef];
};
