import React from 'react';
import { Box, H2, Flex, colors } from '@procore/core-react';
import PT from 'prop-types';
import styled from 'styled-components';

import { ADDITIONAL_CATEGORY_LINKS } from '@/react/consts';
import { UnstyledLink } from '@/react/styles';

const StyledSidebar = styled.div`
  background-color: transparent;
`;

const Divider = styled.div`
  margin: 24px;
  border-bottom: 1px solid ${colors.gray90};
  height: 1px;
`;

const MenuItem = styled(Flex).attrs({ className: 'menu-item' })`
  font-family: inherit;
  font-size: 12px;
  color: ${colors.gray50};
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out 0s;

  &:after {
    background-color: ${(props) =>
      props.active ? colors.orange50 : 'transparent'};
    content: '';
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    transition: all 0.2s ease-in-out 0s;
    width: 5px;
  }

  &:hover {
    background-color: ${colors.gray90};
    color: ${colors.gray15};
    &:after {
      background-color: ${colors.orange50};
    }
  }
`;

const FlexMenuContainer = styled(Flex)`
  flex-direction: column;
  & > * {
    width: 100%;
  }
`;

const endsWith = (str = '', suffix = '') =>
  str.indexOf(suffix, str.length - suffix.length) !== -1;

const isLinkActive = (path) => {
  const location = window.location || { pathname: '' };
  const { pathname } = location;
  return endsWith(pathname, path);
};

const ListingsSidebar = ({ categories }) => (
  <StyledSidebar>
    <FlexMenuContainer style={{ margin: '0 5px' }} direction="column">
      {ADDITIONAL_CATEGORY_LINKS.map((category) => (
        <UnstyledLink href={category.href} key={category.slug}>
          <MenuItem
            padding="sm xl"
            alignItems="center"
            active={isLinkActive(category.slug)}
          >
            {category.name}
          </MenuItem>
        </UnstyledLink>
      ))}
      <Divider />
      <Box paddingBottom="md" paddingLeft="xl" paddingRight="xl">
        <H2>Categories</H2>
      </Box>
      {categories.map((category) => (
        <UnstyledLink
          href={`/${category.type === 'region' ? 'regions' : 'categories'}/${
            category.slug
          }`}
          key={category.slug}
        >
          <MenuItem
            padding="sm xl"
            alignItems="center"
            active={isLinkActive(
              `/${category.type === 'region' ? 'regions' : 'categories'}/${
                category.slug
              }`
            )}
          >
            {category.name}
          </MenuItem>
        </UnstyledLink>
      ))}
    </FlexMenuContainer>
  </StyledSidebar>
);

ListingsSidebar.propTypes = {
  categories: PT.arrayOf(
    PT.shape({
      name: PT.string.isRequired,
      slug: PT.string.isRequired,
      type: PT.string.isRequired,
    })
  ).isRequired,
};

export default ListingsSidebar;
