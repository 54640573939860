import React from 'react'
import Dropdown from '@/react/shared/Dropdown';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const i18n = {
  logout: 'Logout',
  changeCompany: 'Change Company'
};

const Avatar = () => (
  <div
    data-qa="user-avatar"
    className={cx(styles.avatarCircle)}>
    <span className={cx(styles.userIcon)} />
  </div>
);

const UserAvatar = ({ email, company, signOutPath, changeCompanyPath }) => {
  return (
    <Dropdown
      menuStyles={{ right: '-35px' }}
      dropdownDirection="west"
      dropdownTarget={<Avatar />}
    >
      <div className={cx(styles.dropdownContainer)}>
        <div
          data-qa="account-email"
          className={cx(styles.email)}>
          {email}
        </div>
        <div
          data-qa="company-name"
          className={cx(styles.company)}>
          {company}
        </div>
        <div className={cx(styles.footer)}>
          <div
            data-qa="change-company-button"
            className={cx('dropdown-item')}
            onClick={() => window.location = changeCompanyPath}>
            {i18n.changeCompany}
          </div>
          <div
            data-qa="account-logout-button"
            className={cx('dropdown-item')}
            onClick={() => window.location = signOutPath}>
            {i18n.logout}
          </div>
        </div>
      </div>
    </Dropdown>
  )
};

export default UserAvatar;
