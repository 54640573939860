import axios from 'axios';

const trackEvent = (key, params) => {
  axios({
    method: 'post',
    url: '/api/analytics',
    data: {
      key,
      additional_properties: params,
    },
    headers: {
      'X-Forwarded-Host': window.location.host,
      'X-CSRF-TOKEN': document
        .getElementsByName('csrf-token')[0]
        .getAttribute('content'),
    },
  });
};

export default trackEvent;
