import React from 'react';
import PT from 'prop-types';
import { I18nProvider } from '@procore/core-react';
import i18n from '../i18n';
import { AppContextProvider } from '@/react/context/AppContext';
import { SearchProvider } from '@/react/context/SearchContext';
import MarketplaceListing from './MarketplaceListing';

const MarketplaceListingContainer = ({ listing, header }) => {
  return (
    <I18nProvider locale={i18n.locale} translations={i18n.translations}>
      <SearchProvider>
        <AppContextProvider listing={listing} header={header}>
          <MarketplaceListing />
        </AppContextProvider>
      </SearchProvider>
    </I18nProvider>
  );
};

MarketplaceListingContainer.propTypes = {
  header: PT.shape({}).isRequired,
  listing: PT.shape({
    apps: PT.shape([]),
    query: PT.string,
    categories: PT.shape([]),
  }).isRequired,
};

export default MarketplaceListingContainer;
