import React from 'react';
import {
  Wrench,
  PeopleGroup,
  Handshake,
  LightBulb,
  BookInfo,
  Presentation,
} from '@procore/core-icons';

export const ADDITIONAL_CATEGORY_LINKS = [
  {
    href: '/',
    name: 'Featured',
    slug: '/',
  },
  {
    href: '/featured/new',
    name: 'New',
    slug: 'new',
  },
  {
    href: '/listings',
    name: 'All',
    slug: 'listings',
  },
];

export const COMPONENT_MAP = {
  iframe: 'Embedded',
  fullscreen: 'Embedded',
  sidepanel: 'Embedded',
};

export const NAVIGATION_MENU_ITEMS = [
  {
    icon: <Wrench size="lg" />,
    label: 'navbar.app',
    href: 'https://developers.procore.com/documentation/partner-overview',
  },
  {
    icon: <PeopleGroup size="lg" />,
    label: 'navbar.community',
    href: 'https://community.procore.com/s/?utm_source=header&utm_medium=link&utm_campaign=Community%20Header&utm_id=community',
  },
  {
    icon: <Handshake size="lg" />,
    label: 'navbar.partner',
    href: 'https://developers.procore.com/partner-signup',
  },
  {
    icon: <LightBulb size="lg" />,
    label: 'navbar.idea',
    href: 'https://feedback.procore.com/forums/183340-customer-feedback-for-procore-technologies-inc?category_id=78299',
  },
  {
    icon: <Presentation size="lg" />,
    label: 'navbar.webinars',
    href: 'https://www.procore.com/virtual-training?groups=integration-spotlights',
  },
  {
    icon: <BookInfo size="lg" />,
    label: 'navbar.faq',
    href: '/faqs',
  },
];
