export const FUSE_CONFIG = {
  ignoreLocation: true,
  shouldSort: true,
  threshold: 0,
  location: 0,
  distance: 90000, // Set distance to be large to account for long fields
  minMatchCharLength: 2,
  includeMatches: true,
  findAllMatches: false,
  keys: [
    { name: 'name', weight: 50 },
    { name: 'search_description', weight: 30 },
    { name: 'how_it_works', weight: 25 },
    { name: 'description', weight: 20 },
  ]
};
