import React, { useState } from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  colors,
  spacing,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import styled from 'styled-components';
import { List } from '@procore/core-icons';
import ProcoreLogo from '../ProcoreLogo';
import SearchBar from '../SearchBar';
import SupportMenu from '../SupportMenu';
import ProfileBox from '../ProfileBox';
import { UnstyledLink } from '../../styles';
import { useAppContext } from '@/react/context/AppContext';
import { useMediaQuery } from '@/react/hooks/useMediaQuery';
import MobileLogo from '../ProcoreLogo/MobileLogo';
import MobileMenu from '../MobileMenu';

const StyledLogo = styled(Box)`
  width: 100%;
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray90};
  padding: 0 ${spacing.lg}px;
  width: 100%;
  z-index: 10000;
`;

const NavContainer = styled(Box)`
  @media (min-width: 768px) {
    flex: 1;
  }
`;

const MobileMenuContainer = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.white};
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? 'scale(1)' : 'scale(0.8)')};
  transform-origin: bottom center;
  transition: opacity 0.15s ease, transform 0.25s ease;
  z-index: 10001;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

const Navbar = ({ isSearchVisible }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 992px)');
  const isDesktop = useMediaQuery('(max-width: 1200px)');
  const i18n = useI18nContext();
  const { header } = useAppContext();
  const { marketplaceBasePath } = header;

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <>
      <Header data-testid="app-navbar">
        <NavContainer
          display="flex"
          padding="md"
          alignItems="center"
          justifyContent="space-between"
          gap="md"
        >
          <UnstyledLink href={marketplaceBasePath}>
            <StyledLogo
              display="inline-flex"
              gap="md"
              alignItems="center"
              data-testid="procore-logo"
            >
              {!isDesktop ? <ProcoreLogo /> : <MobileLogo />}
              <Typography intent="h1" weight="semibold">
                {i18n.t('global.appMarketplace')}
              </Typography>
            </StyledLogo>
          </UnstyledLink>

          {!isTablet && isSearchVisible && (
            <Box flex={12} data-testid="search-desktop">
              <SearchBar />
            </Box>
          )}

          {!isMobile ? (
            <Box display="inline-flex" gap="md" alignItems="center">
              <SupportMenu />
              <ProfileBox />
            </Box>
          ) : (
            <Button
              data-testid="toggle-mobile-menu"
              variant="tertiary"
              icon={<List />}
              onClick={toggleMenu}
            />
          )}
        </NavContainer>

        {isTablet && isSearchVisible && (
          <Box paddingBottom="md" data-testid="search-tablet">
            <SearchBar />
          </Box>
        )}
      </Header>
      {isMobile && (
        <MobileMenuContainer isOpen={isMenuOpen} data-testid="mobile-menu">
          <MobileMenu toggleMenu={toggleMenu} />
        </MobileMenuContainer>
      )}
    </>
  );
};

Navbar.propTypes = {
  isSearchVisible: PT.bool.isRequired,
};

export default Navbar;
