import React from 'react';

import {
  Box,
  colors,
  Flex,
  FlexList,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import PT from 'prop-types';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import { FUSE_CONFIG } from '@/react/shared/utils/fuseConfig';
import { useAppContext } from '@/react/context/AppContext';
import { useVisibility } from '@/react/hooks/useVisibility';

import {
  ListingsAppCard,
  ListingsSidebar,
  NoResults,
  Navbar,
  SearchBar,
} from '@/react/components';

const SidebarWrapper = styled(Box)`
  @media (min-width: 1001px) {
    flex: 0 1 20%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const ListingsAppsWrapper = styled(Box)`
  @media (min-width: 1001px) {
    flex: 0 1 80%;
  }

  @media (max-width: 1000px) {
    flex: 1 0 100%;
  }
`;

const AppsNotFoundWrapper = styled(Flex)`
  flex: 0 1 80%;
  margin-left: auto;
  margin-right: auto;
`;

// Core React Flexlist removes margin on last child,
// which causes weird wrapping in our case here.
const FlexListMarginChild = styled(FlexList)`
  & > *:last-child {
    margin-right: 32px !important;
  }
`;

const ListingContainer = styled.div`
  max-width: 1200px;
  margin: 50px auto;
`;

const Hero = styled(Box)`
  background: url(/images/marketplace_header.png) no-repeat top center;
  background-size: cover;
  height: 400px;
`;

const MarketplaceListing = () => {
  const [isVisible, elementRef] = useVisibility(100);
  const { listing } = useAppContext();
  const i18n = useI18nContext();
  const { apps, categories, query } = listing;

  apps
    .filter((app) => app.name.startsWith('Arcoro'))
    .map(
      (app) =>
        (app.search_description = app.search_description.replace(/^/, 'HR '))
    );

  const fuse = new Fuse(apps, FUSE_CONFIG);
  const listings = query
    ? fuse.search(query).map((result) => result.item)
    : apps;

  listings
    .filter((app) => app.name.startsWith(' '))
    .map(
      (app) =>
        (app.search_description = app.search_description.replace(/^HR /, ''))
    );

  return (
    <>
      <Navbar isSearchVisible={!isVisible} />

      <Hero
        display="flex-column"
        padding="xl"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          intent="h1"
          color="gray98"
          weight="bold"
          style={{ fontSize: '34px', lineHeight: '40px' }}
        >
          {i18n.t('hero.tagline')}
        </Typography>
        <Box ref={elementRef} display="flex" padding="lg 0">
          <SearchBar
            styledSearch={{
              backgroundColor: colors.white,
              hoverColor: colors.gray96,
            }}
          />
        </Box>
      </Hero>

      <ListingContainer>
        <Flex>
          {categories.length > 0 && (
            <SidebarWrapper>
              <ListingsSidebar categories={categories} />
            </SidebarWrapper>
          )}
          {listings.length > 0 ? (
            <ListingsAppsWrapper>
              <FlexListMarginChild
                boxProps={{
                  flex: '0 1 30%',
                  marginBottom: 'xl',
                }}
                justifyContent="center"
                space="xxl"
                wrap="wrap"
              >
                {listings.map((listing) => (
                  <ListingsAppCard app={listing} key={listing.slug} />
                ))}
              </FlexListMarginChild>
            </ListingsAppsWrapper>
          ) : (
            <AppsNotFoundWrapper justifyContent="center">
              <Typography color="gray70">
                <NoResults />
              </Typography>
            </AppsNotFoundWrapper>
          )}
        </Flex>
      </ListingContainer>
    </>
  );
};

export default MarketplaceListing;
