$(function() {
  $('[data-behavior="modal"]').click(function(event) {
      event.preventDefault();

      $(this).modal({
        fadeDuration: 500
      });
    })
});


// Procore Modal
$(function() {
  $('[data-behavior="modal"]').click(function(event) {
      event.preventDefault();

      $(this).modal({
        fadeDuration: 500
      });
    })
});
