/**
 * This helper will open up a jquery modal from a click event.
 * The Click target must be a link whose href is the ID
 * of the modal you wish to open.
 * Ex: <a href="#myModalId"></a>
 * http://jquerymodal.com/
 * NOTE: This can only be used where jquery.modal is defined.
 */
export const triggerModalFromClickEvent = (e) => {
  e.preventDefault();

  $(e.target).modal({
    fadeDuration: 500
  });
};
