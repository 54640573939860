import trackEvent from '@/scripts/analytics';

export const sendAnalytics = (
  action,
  category,
  label,
  procoreLabel,
  procoreAdditionalFields,
  gtagValue
) => {
  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      gtagValue,
    });
  }
  trackEvent(`marketplace.apps.${procoreLabel}`, {
    app_name: name,
    ...procoreAdditionalFields,
  });
};
