export const debounce = (func, delay) => {
  let timer;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

export const truncate = ( str, n, useWordBoundary ) => {
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n-1);
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(" "))
    : subString) + "...";
};
