import React from 'react';
import PT from 'prop-types';
import { Box, Flex, H1, H2, useI18nContext } from '@procore/core-react';
import { useSanitizedString } from '@/react/hooks/useSanitizedString';

const OverviewSection = ({ description }) => {
  const i18n = useI18nContext();

  const sanitizedDescription = useSanitizedString(description);

  return (
    <Flex
      data-testid="overview-section"
      direction="column"
      paddingTop="xl"
      paddingBottom="xl"
    >
      <Box margin="lg none">
        <H1>{i18n.t('appDetails.titles.overview')}</H1>
      </Box>
      <Box margin="sm none">
        <H2>{i18n.t('appDetails.titles.summary')}</H2>
      </Box>
      <Box
        margin="sm none"
        data-testid="description"
        className="display-white-space"
        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
      />
    </Flex>
  );
};

OverviewSection.propTypes = {
  description: PT.string.isRequired,
};

export default OverviewSection;
