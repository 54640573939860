import React from 'react';
import { ExternalLink, ShieldStar } from '@procore/core-icons';
import {
  Box,
  colors,
  Link,
  Popover,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import styled from 'styled-components';

const PopoverHeader = styled(Box)`
  border-bottom: 1px solid ${colors.gray90};
`;

const PopoverBody = styled(Box)`
  border-bottom: 1px solid ${colors.gray90};
`;

const SecurityBadge = () => {
  const i18n = useI18nContext();
  return (
    <Popover.Content style={{ zIndex: 5, maxWidth: '300px', width: '100%' }}>
      <PopoverHeader display="flex" padding="xl" gap="sm" alignItems="center">
        <ShieldStar color={colors.blue50} />
        <Typography intent="h3">{i18n.t('securityBadge.title')}</Typography>
      </PopoverHeader>
      <PopoverBody display="flex-column" padding="xl" gap="md">
        <Typography intent="body">
          {i18n.t('securityBadge.partnerAnswered')}{' '}
          <Link href="#security-section">{i18n.t('securityBadge.link')}</Link>
        </Typography>
        <Typography intent="body">
          {i18n.t('securityBadge.responsibility')}
        </Typography>
        <Link
          href="https://support.procore.com/faq/what-is-the-security-and-trust-self-certified-badge"
          target="_blank"
        >
          {i18n.t('securityBadge.learnMore')} <ExternalLink size="sm" />
        </Link>
      </PopoverBody>
    </Popover.Content>
  );
};

export default SecurityBadge;
