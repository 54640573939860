import React from 'react';
import PT from 'prop-types';
import {
  Box,
  Flex,
  LinkButton,
  Typography,
  useI18nContext,
} from '@procore/core-react';
import { UnstyledLink, DropdownItem, Divider } from '@/react/styles';
import { highlight } from '@/utils/highlight';

const SearchResults = ({ results, searchTerm }) => {
  const RESULTS_LIMIT = 7;
  const i18n = useI18nContext();
  const highlightedResults = highlight(results?.slice(0, RESULTS_LIMIT));

  return highlightedResults.length > 0 ? (
    highlightedResults.map((result, idx, arr) => {
      const { highlightedItem: app } = result;
      const viewAll = arr.length > 1 && idx === arr.length - 1;

      return (
        <Box key={app.slug} data-testid="search-results">
          <UnstyledLink href={`/apps/${app.slug}`}>
            <DropdownItem>
              <Flex alignItems="center">
                <Flex alignItems="center" justifyContent="center">
                  <img
                    alt={`Logo for ${app.name}`}
                    src={app.small_logo_url}
                    style={{ width: '35px', maxHeight: '35px' }}
                  />
                </Flex>
                <Flex paddingLeft="md" direction="column">
                  <Typography intent="body">{app.name}</Typography>
                  <Typography intent="body" color="gray45">
                    {app.search_description}
                  </Typography>
                </Flex>
              </Flex>
            </DropdownItem>
          </UnstyledLink>
          {viewAll && (
            <Box
              data-testid="view-all-results"
              display="flex-column"
              gap="sm"
              justifyContent="flex-end"
            >
              <Divider />
              <LinkButton
                key={searchTerm}
                href={`/search_results?query=${searchTerm}`}
              >
                {i18n.t('labels.viewAll')}
              </LinkButton>
            </Box>
          )}
        </Box>
      );
    })
  ) : (
    <Typography
      data-testid="no-results"
      intent="body"
      color="gray10"
      weight="semibold"
    >
      {i18n.t('labels.noResults')}
    </Typography>
  );
};

SearchResults.propTypes = {
  results: PT.arrayOf(PT.shape({})).isRequired,
  searchTerm: PT.string.isRequired,
};

export default SearchResults;
