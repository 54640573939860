import { useState, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';

const sanitizeOptions = {
  allowedTags: ['a', 'li', 'ul', 'h1', 'h2', 'h3', 'div'],
  allowedAttributes: {
    a: ['href', 'style', 'target'],
    li: ['style'],
    div: ['style'],
  },
  allowedStyles: {
    '*': {
      color: [/^.*$/], // Allow any color
    },
  },
};

export const useSanitizedString = (string) => {
  const [sanitizedString, setSanitizedString] = useState('');

  useEffect(() => {
    setSanitizedString(sanitizeHtml(string, sanitizeOptions));
  }, [string]);

  return sanitizedString;
};

export const useSanitizedArray = (array) => {
  const [sanitizedArray, setSanitizedArray] = useState([]);

  useEffect(() => {
    setSanitizedArray(
      array.map((string) => sanitizeHtml(string, sanitizeOptions))
    );
  }, [array]);

  return sanitizedArray;
};
