import React from 'react';
import classNames from 'classnames';
import axios from 'axios';
import qs from 'qs';
import { Button } from '@procore/core-react';
import { getHeaders, POST_HEADERS } from '@/utils/apiUtil';
import styles from './styles.module.scss';
import Popover from '@/react/shared/Popover';

const cx = classNames.bind(styles);

const I18n = {
  requestSent: 'App Requested',
  requestInstall: 'Request App',
  modalTitle: 'Request App',
  cancel: 'Cancel',
  submit: 'Submit',
  notesPlaceholder:
    'Only company administrators can install apps. Include an optional note for your administrators here.',
  error: 'Something went wrong, please try again later.',
  sentInfo:
    'Your request to install this app has been sent to your company administrators.',
};

const api = {
  requestInstall: (installRequestsPath, developerAppId, notes) => {
    return axios(installRequestsPath, {
      method: 'post',
      data: { developer_app_id: developerAppId, notes },
      headers: getHeaders(POST_HEADERS),
    });
  },
};

class InstallRequestButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { notes: '', requestSent: false, error: null };
  }

  componentDidMount() {
    $('#install-request-modal').on($.modal.AFTER_CLOSE, () => {
      this.setNotes('');
    });
  }

  setRequestSent(requestSent) {
    this.setState({ requestSent });
  }

  setNotes(notes) {
    this.setState({ notes });
  }

  setError(error) {
    this.setState({ error });
  }

  requestInstall() {
    const { installRequestsPath, developerAppId, sendAnalytics } = this.props;
    const self = this;

    api
      .requestInstall(installRequestsPath, developerAppId, this.state.notes)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          self.setRequestSent(true);
          sendAnalytics('submit', 'Form', 'Install Request');
        }
      })
      .catch((e) => {
        self.setError(I18n.error);
      });
    $.modal.close();
  }

  render() {
    const { modalVisible, requestSent, notes, error } = this.state;
    const { requested, sendAnalytics } = this.props;

    return (
      <div className={cx(styles.installRequestContainer)}>
        <div className={cx(styles.buttonContainer)}>
          {error && (
            <Popover className={cx(styles.popover)}>{I18n.error}</Popover>
          )}
          {requested || requestSent ? (
            <>
              <Popover className={cx(styles.popover)}>{I18n.sentInfo}</Popover>
              <div className={cx(styles.button, 'primaryButton', 'disabled')}>
                {I18n.requestSent}
              </div>
            </>
          ) : (
            <a
              href="#install-request-modal"
              rel="modal:open"
              onClick={() =>
                sendAnalytics(
                  'open',
                  'Modal',
                  'Install Request',
                  'installation_request.clicked'
                )
              }
            >
              <Button disabled={error} size="lg">
                {I18n.requestInstall}
              </Button>
            </a>
          )}
        </div>
        <div
          id="install-request-modal"
          className={cx('modal', styles.requestModal)}
          style={{ display: 'none' }}
        >
          <h2>{I18n.modalTitle}</h2>

          <div>
            <textarea
              value={notes}
              onChange={(e) => this.setNotes(e.target.value)}
              placeholder={I18n.notesPlaceholder}
              className={cx(styles.notesInput)}
            />
          </div>

          <div className={cx(styles.modalFooter)}>
            <div className={cx(styles.footerButtons)}>
              <a
                href="#"
                rel="modal:close"
                className={cx(
                  styles.footerButton,
                  'primaryButton',
                  'primaryButtonSmall'
                )}
                onClick={() =>
                  sendAnalytics(
                    'close',
                    'Modal',
                    'Install Request',
                    'installation_request.closed',
                    { request_sent: this.state.requestSent }
                  )
                }
              >
                {I18n.cancel}
              </a>
              <a
                href="#"
                className={cx(
                  styles.footerButton,
                  'primaryButton',
                  'primaryButtonSmall'
                )}
                onClick={() => this.requestInstall()}
              >
                {I18n.submit}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstallRequestButton;
