import React from 'react';
import { Help } from '@procore/core-icons';
import styled from 'styled-components';
import {
  Box,
  Grid,
  OverlayTrigger,
  Typography,
  useI18nContext,
  Button,
  colors,
} from '@procore/core-react';
import { DropdownMenu, UnstyledLink } from '@/react/styles';
import { NAVIGATION_MENU_ITEMS } from '@/react/consts';

const InfoButton = styled(Button)`
  border-radius: 100px;
  background-color: ${colors.gray96};
`;

const MenuTile = styled(Box)`
  background-color: ${colors.gray96};
  width: 124px;
  height: 124px;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.15s ease;

  &:hover {
    background-color: ${colors.gray90};
  }
`;

const SupportMenu = () => {
  const ITEMS_PER_ROW = 2;
  const i18n = useI18nContext();

  // group menuItems into pairs for 2x3 layout using
  const menuRows = NAVIGATION_MENU_ITEMS.reduce((rows, item, idx) => {
    if (idx % ITEMS_PER_ROW === 0) {
      rows.push([item]);
    } else {
      rows[rows.length - 1].push(item);
    }
    return rows;
  }, []);

  const renderMenuItem = (item, key) => (
    <Grid.Col key={key}>
      <UnstyledLink href={item.href} target="_blank">
        <MenuTile
          display="flex-column"
          gap="sm"
          padding="sm"
          alignItems="center"
          justifyContent="center"
        >
          {item.icon}
          <Typography intent="small" weight="semibold">
            {i18n.t(item.label)}
          </Typography>
        </MenuTile>
      </UnstyledLink>
    </Grid.Col>
  );

  const renderMenu = (
    <DropdownMenu>
      <Grid gutterX="sm" gutterY="sm">
        {menuRows.map((row, rowIndex) => {
          const rowKey = `row_${rowIndex}`;
          return (
            <Grid.Row key={rowKey}>
              {row.map((item, itemIndex) =>
                renderMenuItem(item, `row_${itemIndex}`)
              )}
            </Grid.Row>
          );
        })}
      </Grid>
    </DropdownMenu>
  );

  return (
    <Box data-testid="support-menu" display="flex">
      <OverlayTrigger placement="bottom" overlay={renderMenu}>
        <InfoButton
          icon={<Help />}
          size="lg"
          aria-label={i18n.t('ariaLabels.supportButton')}
          variant="secondary"
        />
      </OverlayTrigger>
    </Box>
  );
};

export default SupportMenu;
