import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  H2,
  Modal,
  P,
  Tooltip,
  useI18nContext,
} from '@procore/core-react';

const ContactForm = ({
  appName,
  email = 'example@example.com',
  company = 'Company',
  developerAppId = '',
  contactUsEmail,
  createLead,
  sendAnalytics = () => {},
  isSignedIn = false,
  isInstalled = false,
}) => {
  const i18n = useI18nContext();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const openContactModal = useCallback(() => {
    if (!window.MktoForms2) {
      return;
    }

    sendAnalytics('open', 'Modal', 'Contact Us', 'contact_form.opened');
    setModalOpen(true);

    MktoForms2.loadForm(
      '//app-sj11.marketo.com',
      '788-HIQ-636',
      6597,
      (form) => {
        form.vals({
          Email: email,
          Company: company,
          Marketplace_Interest__c: appName,
          marketplaceDeveloperAppId: developerAppId,
          marketplacePartnerEmail: contactUsEmail,
          marketplaceProcoreBuiltApp: createLead,
        });

        form.onSuccess(() => {
          setFormSubmitted(true);
          sendAnalytics('submit', 'Form', 'Contact Us', 'contact_form.sent');
          return false;
        });
      }
    );
  }, [
    appName,
    email,
    company,
    developerAppId,
    contactUsEmail,
    createLead,
    sendAnalytics,
  ]);

  const closeContactModal = useCallback(() => {
    setModalOpen(false);
    sendAnalytics(
      'close',
      'Modal',
      'Contact Us',
      'contact_form.closed',
      { sent_form: formSubmitted ? 1 : 0 },
      formSubmitted ? 1 : 0
    );
  }, [formSubmitted, sendAnalytics]);

  return (
    <div data-testid="contact-form">
      {isSignedIn ? (
        <Button
          disabled={isInstalled}
          variant="secondary"
          onClick={openContactModal}
          size="lg"
        >
          {i18n.t('labels.contactUs', { defaultValue: 'Contact Us' })}
        </Button>
      ) : (
        <Tooltip
          overlay={i18n.t('contactUs.mustLogIn', {
            defaultValue: 'Log in to contact the Partner',
          })}
          placement="bottom"
          trigger="hover"
        >
          <Box display="block">
            <Button
              variant="secondary"
              onClick={openContactModal}
              size="lg"
              disabled
            >
              {i18n.t('labels.contactUs', { defaultValue: 'Contact Us' })}
            </Button>
          </Box>
        </Tooltip>
      )}
      <Modal open={modalOpen} onClickOverlay={closeContactModal}>
        <Modal.Header onClose={closeContactModal}>
          {i18n.t('labels.contactUs', { defaultValue: 'Contact Us' })}
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <Box>
            {!formSubmitted ? (
              <P id="mkto_start_form_p">
                {i18n.t('contactUs.formDescription', {
                  defaultValue:
                    'Please fill out the form below. A representative will be in touch with you shortly with more information on pricing and how to get started.',
                })}
              </P>
            ) : (
              <Box>
                <H2>
                  {i18n.t('labels.thankYou', {
                    defaultValue:
                      'Thanks for visiting our App Marketplace and requesting information on',
                  })}
                  !
                </H2>
                <P>
                  {i18n.t('contactUs.thanksForVisiting', {
                    defaultValue:
                      'We have received your request and a representative will contact you shortly.',
                  })}{' '}
                  {appName}
                </P>
                <P>
                  {i18n.t('contactUs.submitted', {
                    defaultValue:
                      'We have received your request and a representative will contact you shortly.',
                  })}
                </P>
              </Box>
            )}
            {!formSubmitted && <form id="mktoForm_6597" />}

            <P id="marketo-blocked" style={{ display: 'none' }}>
              {i18n.t('contactUs.issues', {
                defaultValue:
                  'If you are having issues viewing the form, please disable your Adblock.',
              })}
            </P>
          </Box>
        </Modal.Body>
      </Modal>
    </div>
  );
};

ContactForm.propTypes = {
  appName: PropTypes.string.isRequired,
  email: PropTypes.string,
  company: PropTypes.string,
  developerAppId: PropTypes.string,
  contactUsEmail: PropTypes.string,
  createLead: PropTypes.bool,
  sendAnalytics: PropTypes.func,
};

export default ContactForm;
