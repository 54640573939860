import axios from 'axios';

const appInstallPost = (app) =>
  axios({
    method: 'post',
    url: '/apps/app_installation',
    data: {
      app,
    },
    headers: {
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
    },
    // eslint-disable-next-line no-return-assign
  });

export { appInstallPost };
