import React from 'react';

import { Box, Flex, FlexList, Typography } from '@procore/core-react';
import PT from 'prop-types';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import { FUSE_CONFIG } from '@/react/shared/utils/fuseConfig';
import {
  ListingsAppCard,
  ListingsSidebar,
  NoResults,
} from '@/react/components';

const SidebarWrapper = styled(Box)`
  @media (min-width: 1001px) {
    flex: 0 1 20%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

const ListingsAppsWrapper = styled(Box)`
  @media (min-width: 1001px) {
    flex: 0 1 80%;
  }

  @media (max-width: 1000px) {
    flex: 1 0 100%;
  }
`;

const AppsNotFoundWrapper = styled(Flex)`
  flex: 0 1 80%;
  margin-left: auto;
  margin-right: auto;
`;

// Core React Flexlist removes margin on last child,
// which causes weird wrapping in our case here.
const FlexListMarginChild = styled(FlexList)`
  & > *:last-child {
    margin-right: 32px !important;
  }
`;

const ListingsIndex = ({ apps, categories, query }) => {
  apps
    .filter((app) => app.name.startsWith('Arcoro'))
    .map(
      (app) =>
        (app.search_description = app.search_description.replace(/^/, 'HR '))
    );

  const fuse = new Fuse(apps, FUSE_CONFIG);
  const listings = query
    ? fuse.search(query).map((result) => result.item)
    : apps;

  listings
    .filter((app) => app.name.startsWith('Arcoro'))
    .map(
      (app) =>
        (app.search_description = app.search_description.replace(/^HR /, ''))
    );

  return (
    <Flex>
      {categories.length > 0 && (
        <SidebarWrapper>
          <ListingsSidebar categories={categories} />
        </SidebarWrapper>
      )}
      {listings.length > 0 ? (
        <ListingsAppsWrapper>
          <FlexListMarginChild
            boxProps={{
              flex: '0 1 30%',
              marginBottom: 'xl',
            }}
            justifyContent="center"
            space="xxl"
            wrap="wrap"
          >
            {listings.map((listing) => (
              <ListingsAppCard app={listing} key={listing.slug} />
            ))}
          </FlexListMarginChild>
        </ListingsAppsWrapper>
      ) : (
        <AppsNotFoundWrapper justifyContent="center">
          <Typography color="gray70">
            <NoResults />
          </Typography>
        </AppsNotFoundWrapper>
      )}
    </Flex>
  );
};

ListingsIndex.propTypes = {
  apps: PT.arrayOf(
    PT.shape({
      image_url: PT.string.isRequired,
      search_description: PT.string.isRequired,
      components: PT.arrayOf(PT.string).isRequired,
      slug: PT.string.isRequired,
      name: PT.string.isRequired,
    })
  ).isRequired,
  query: PT.string,
  categories: PT.arrayOf(
    PT.shape({
      name: PT.string.isRequired,
      slug: PT.string.isRequired,
      type: PT.string.isRequired,
    })
  ).isRequired,
};

export default ListingsIndex;
