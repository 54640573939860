import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import PT from 'prop-types';
import Fuse from 'fuse.js';
import axios from 'axios';
import { FUSE_CONFIG } from '@/react/shared/utils/fuseConfig';
import { debounce } from '@/react/shared/utils/helpers';
import trackEvent from '@/scripts/analytics';

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
  const DEBOUNCE_DELAY = 300;
  const SEARCH_TERM_LENGTH = 2;
  const MAX_SEARCH_RESULTS = 15;
  const [searchTerm, setSearchTerm] = useState('');
  const [fuse, setFuse] = useState(null);
  const [appSearchResults, setAppSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch and initialize Fuse only once
    axios.get('/search_data').then((res) => {
      setFuse(new Fuse(res.data, FUSE_CONFIG));
      setIsLoading(false);
    });
  }, []);

  const performSearch = useMemo(
    () =>
      debounce((term) => {
        if (fuse && term.length > SEARCH_TERM_LENGTH) {
          const results = fuse.search(term).slice(0, MAX_SEARCH_RESULTS);
          setAppSearchResults(results);
          trackEvent('marketplace.apps.search.input', {
            marketplace_search_term: term,
            marketplace_search_count: results.length,
          });
        } else {
          setAppSearchResults([]);
        }
      }, DEBOUNCE_DELAY),
    [fuse]
  );

  useEffect(() => {
    performSearch(searchTerm);
  }, [searchTerm, performSearch]);

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({ searchTerm, setSearchTerm, appSearchResults, isLoading }),
    [searchTerm, setSearchTerm, appSearchResults, isLoading]
  );

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PT.node.isRequired,
};
