import React from 'react';
import PropTypes from 'prop-types';
import { Box, colors, useI18nContext } from '@procore/core-react';
import styled from 'styled-components';
import { useAppContext } from '@/react/context/AppContext';
import MenuHeader from './MenuHeader';
import MenuBody from './MenuBody';
import MenuFooter from './MenuFooter';

const MenuWrapper = styled(Box)`
  background-color: ${colors.white};
  height: 100vh;
`;

const MobileMenu = ({ toggleMenu }) => {
  const i18n = useI18nContext();
  const { header } = useAppContext();
  const {
    marketplaceBasePath,
    signInPath,
    currentCompany,
    currentUser,
    isSignedIn,
    signOutPath,
    changeCompanyPath,
  } = header;
  return (
    <MenuWrapper display="flex-column">
      <MenuHeader
        toggleMenu={toggleMenu}
        marketplaceBasePath={marketplaceBasePath}
        title={i18n.t('global.appMarketplace')}
      />

      <MenuBody />

      <MenuFooter
        isSignedIn={isSignedIn}
        signInPath={signInPath}
        currentUser={currentUser}
        currentCompany={currentCompany}
        changeCompanyPath={changeCompanyPath}
        signOutPath={signOutPath}
      />
    </MenuWrapper>
  );
};

MobileMenu.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default MobileMenu;
