export const highlight = (
  results,
  maxLength = 40,
  highlightClass = 'highlight'
) => {
  const renderHighlightedText = (text, regions = []) => {
    let truncated = false;
    let currStringLen = 0;

    const checkLengthAndTrunc = (string) => {
      if (currStringLen + string.length > maxLength) {
        const truncatedString = string.slice(0, maxLength - currStringLen);
        currStringLen += truncatedString.length;
        truncated = true;
        return truncatedString;
      }
      currStringLen += string.length;
      return string;
    };

    const content = regions.reduce((acc, [start, end], idx) => {
      const beforeMatch = checkLengthAndTrunc(text.slice(currStringLen, start));
      const highlightedMatch = checkLengthAndTrunc(text.slice(start, end + 1));
      const afterMatch =
        idx === regions.length - 1
          ? checkLengthAndTrunc(text.slice(end + 1))
          : '';

      const key = `match_${idx}`;
      acc.push(
        <span key={key}>
          {beforeMatch}
          <strong className={highlightClass}>{highlightedMatch}</strong>
          {afterMatch}
        </span>
      );
      return acc;
    }, []);

    return truncated ? [...content, '...'] : content;
  };

  return results
    ? results
        .filter(({ matches }) => matches && matches.length)
        .map(({ item, matches }) => {
          const highlightedItem = { ...item };

          matches.forEach((match) => {
            highlightedItem[match.key] = renderHighlightedText(
              match.value,
              match.indices
            );
          });

          return { highlightedItem, matches };
        })
    : [];
};
