import React from 'react';
import PT from 'prop-types';
import { I18nProvider } from '@procore/core-react';
import i18n from '../i18n';
import AppDetails from './AppDetails';
import { AppContextProvider } from '@/react/context/AppContext';
import { SearchProvider } from '@/react/context/SearchContext';
import { Navbar } from '@/react/components';

const MarketplaceAppContainer = ({ appDetails, header }) => {
  return (
    <I18nProvider locale={i18n.locale} translations={i18n.translations}>
      <SearchProvider>
        <AppContextProvider appDetails={appDetails} header={header}>
          <Navbar isSearchVisible />
          <AppDetails />
        </AppContextProvider>
      </SearchProvider>
    </I18nProvider>
  );
};

MarketplaceAppContainer.propTypes = {
  appDetails: PT.shape({}).isRequired,
  header: PT.shape({}).isRequired,
};

export default MarketplaceAppContainer;
