import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Flex,
  LinkButton,
  Grid,
  H1,
  H3,
  Link,
  P,
  Popover,
} from '@procore/core-react';
import { ChevronDown, ChevronUp, Info } from '@procore/core-icons';
import PT from 'prop-types';
import Slider from 'react-slick';
import trackEvent from '../../../../scripts/analytics';
import * as Styles from '@/react/styles';
import {
  WistiaExtract,
  VersionHistory,
  TopNavHeader,
  InstallRequestButton,
  ContactForm,
} from '@/react/components';
import { appInstallPost } from '@/react/api';

const componentMap = {
  iframe: 'Embedded',
  fullscreen: 'Embedded',
  sidepanel: 'Embedded',
};

const ListingsShow = ({
  app,
  installationRequest,
  companyInstallationRequestsEnabled,
  companyName,
  user,
  headerProps,
  version,
}) => {
  const {
    built_by,
    email,
    name,
    html,
    installed,
    pricing,
    contact_us_email,
    create_lead,
    search_description,
    small_logo,
    small_logo_url,
    website,
    category_names,
    components,
    developer_app_id,
    direct_installable,
    install_path,
    price_button,
    screenshots,
    regions,
    countries,
    versioning_history,
    new_draft_preview,
    security_badge,
    security_questionnaire_document,
  } = app;

  const filteredComponents =
    components?.map((component) => componentMap[component]).filter(Boolean) ||
    {};

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  const sendAnalytics = (
    action,
    category,
    label,
    procoreLabel,
    procoreAdditionalFields,
    gtagValue
  ) => {
    if (typeof gtag !== 'undefined') {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        gtagValue,
      });
    }
    trackEvent(`marketplace.apps.${procoreLabel}`, {
      app_name: name,
      ...procoreAdditionalFields,
    });
  };

  const colWidthsContent = { tabletLg: 8, tabletMd: 12 };
  const colWidthSidebar = { tabletLg: 4, tabletMd: 12 };

  const [parsedHTML, setParsedHTML] = useState({
    video: null,
    description: null,
  });
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const sliderSettings = {
    focusOnSelect: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: true,
    beforeChange: (curr, index) => {
      sendAnalytics('click', 'Media Slider', index, 'media_slider.changed', {
        index,
      });
      setCurrentImageUrl(
        index === 0 && parsedHTML.video
          ? ''
          : screenshots[parsedHTML.video ? index - 1 : index].url
      );
    },
    prevArrow: (
      <Styles.SlickArrowPrev>
        <img src={ imagePath('banner-backgrounds/arrow-left.png') } alt="" />
      </Styles.SlickArrowPrev>
    ),
    nextArrow: (
      <Styles.SlickArrowNext>
        <img src={ imagePath('banner-backgrounds/arrow-right.png') } alt="" />
      </Styles.SlickArrowNext>
    ),
  };

  /**
   * Extracts Wistia video and script from HTML passed in from dev portal
   * so we can render it in the media slider
   * This can be removed if we migrate away from using raw HTML
   */

  useEffect(() => {
    WistiaExtract(html, setParsedHTML);
  }, []);

  const getActionButton = () => {
    if (installed) {
      return <Button disabled>Installed</Button>;
    }

    if (direct_installable) {
      if (!user.isSignedIn) {
        return (
          <form method="post" action="/auth/procore">
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').content}
            />
            <Button type="submit" data-qa="install-signin-button" size="lg">
              Login to Install
            </Button>
          </form>
        );
      }

      if (!user.isCompanyAdmin) {
        if (companyInstallationRequestsEnabled) {
          return (
            <InstallRequestButton
              installRequestsPath={installationRequest.apiPath}
              developerAppId={developer_app_id}
              requested={installationRequest.present}
              sendAnalytics={sendAnalytics}
            />
          );
        }
        const content = (
          <Popover.Content>
            <Box padding="md">
              <P>You must be a company admin to install an app</P>
            </Box>
          </Popover.Content>
        );
        return (
          <Popover overlay={content} trigger="hover" key="example-2">
            {() => (
              <Box>
                <Button disabled data-qa="install-signin-button">
                  Install App
                </Button>
              </Box>
            )}
          </Popover>
        );
      }
      if (install_path) {
        return (
          <Button
            id="app-install-button"
            onClick={() => {
              sendAnalytics(
                'click',
                'Button',
                'Install App',
                'install_button.clicked'
              );
              appInstallPost(app);
              window.location = install_path;
            }}
          >
            Install App
          </Button>
        );
      }
    }
    return null;
  };

  const renderSecurityCertifiedSection = () => (
    <>
      <Box style={{ borderBottom: '1px solid #f0f0f0', padding: '16px' }}>
        <Flex
          onClick={toggleExpanded}
          alignContent="flex-start"
          alignItems="center"
        >
          {expanded ? <ChevronUp /> : <ChevronDown />}
          <img
            src={ imagePath('Copliot-Badge.png') }
            alt=""
            style={{ paddingLeft: '6px', paddingRight: '16px' }}
          />
        </Flex>
        {expanded && (
          <>
            <P style={{ fontSize: '14px' }}>
              <b>Partner Security Review | Self-Certified Responses</b>
            </P>
            <P style={{ fontSize: '12px' }}>
              The following partner has supplied answers to a set of standard
              questions, self-certifying their adherence to specific security
              requirements.
              <Styles.OrangeLink
                href={security_questionnaire_document}
                target="_blank"
              >
                {'  '}View the partner's answers here.
              </Styles.OrangeLink>
              <br />
              <br />
              <b>
                The partner is solely responsible for the accuracy of the
                information provided.
              </b>
              <Styles.OrangeLink
                href="https://support.procore.com/faq/what-is-the-security-and-trust-self-certified-badge"
                target="_blank"
              >
                {'  '}Learn more about the badge here.
              </Styles.OrangeLink>
            </P>
          </>
        )}
      </Box>
      <br />
    </>
  );

  const renderRegions = () => {
    ((countries && Object.keys(countries).length > 0) ||
      regions?.length > 0) && (
      <>
        <H3>
          Available in {}
          <Link
            onClick={() =>
              sendAnalytics(
                'click',
                'Link',
                'Supported Regions FAQ',
                'supported_regions_faq.clicked'
              )
            }
            href="https://support.procore.com/faq/what-are-supported-regions-on-apps"
            style={{ color: 'gray' }}
            target="_blank"
          >
            <Info size="sm" />
          </Link>
        </H3>
        {regions.map((region, idx) => (
          <Box style={{ borderBottom: '1px solid #f0f0f0' }} key={idx}>
            <P>{region}</P>
          </Box>
        ))}
        {Object.keys(countries).map((region) => {
          const rc = countries[region];
          return regions.includes(region) ? null : (
            <>
              <P>
                <strong>
                  {region} ({rc.length})
                </strong>
              </P>
              <Box
                style={{
                  marginBottom: '14px',
                  borderBottom: '1px solid #f0f0f0',
                }}
              >
                <ul>
                  {rc.map((regionCountry) => (
                    <li>{regionCountry}</li>
                  ))}
                </ul>
              </Box>
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      {screenshots?.length > 0 && (
        <Styles.ImageModal
          open={imageModalOpen}
          onClickOverlay={() => setImageModalOpen(false)}
        >
          <Styles.ModalImageBody>
            <Box style={{ position: 'relative' }}>
              <Styles.CloseImageModalButton
                onClick={() => setImageModalOpen(false)}
              />
              <Styles.ModalImage
                src={currentImageUrl || screenshots[0]?.url}
                alt=""
              />
            </Box>
          </Styles.ModalImageBody>
        </Styles.ImageModal>
      )}

      <Flex justifyContent="center">
        <TopNavHeader {...headerProps} />
        <Flex direction="column">
          <Styles.Container padding="100px 50px">
            <Grid colStackCap="tabletMd" gutterX={{ tabletLg: 'xxl' }}>
              <Grid.Row>
                <Grid.Col colWidth={colWidthsContent}>
                  <Flex alignItems="center" marginBottom="md">
                    <Styles.SmallLogoWrapper
                      marginRight="xl"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Styles.SmallLogo
                        src={
                          small_logo_url ||
                          small_logo?.url ||
                          imagePath('logo-placeholder.png')
                        }
                      />
                    </Styles.SmallLogoWrapper>
                    <Flex direction="column" justifyContent="center">
                      <H1>{name}</H1>
                      <P style={{ marginBottom: 0, marginTop: '10px' }}>
                        {search_description}
                      </P>
                    </Flex>
                  </Flex>
                </Grid.Col>
                <Grid.Col colWidth={colWidthSidebar}>
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100%' }}
                  >
                    <Box marginRight="xl">{getActionButton()}</Box>
                    {contact_us_email || create_lead ? (
                      <ContactForm
                        appName={name}
                        developerAppId={developer_app_id}
                        contactUsEmail={contact_us_email}
                        createLead={create_lead}
                        email={user?.email}
                        company={companyName}
                        sendAnalytics={sendAnalytics}
                        isSignedIn={true}
                      />
                    ) : (
                      <LinkButton
                        href={price_button}
                        variant="secondary"
                        onClick={() =>
                          sendAnalytics(
                            'click',
                            'Link',
                            'Learn How',
                            'learn_how.clicked'
                          )
                        }
                        target="_blank"
                      >
                        Learn How
                      </LinkButton>
                    )}
                  </Flex>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col colWidth={colWidthsContent}>
                  <Styles.SlickWrapper marginTop="md">
                    <Slider {...sliderSettings}>
                      {parsedHTML.video}
                      {screenshots?.length === 0 && (
                        <P>
                          <img
                            src={ imagePath('app-image-placeholder.png') }
                            alt=""
                            style={{ width: '600px', height: '400px' }}
                          />
                        </P>
                      )}
                      {screenshots?.map((slide) => (
                        <Styles.Slide
                          src={slide.url}
                          key={slide.url}
                          onClick={() => setImageModalOpen(true)}
                        />
                      ))}
                    </Slider>
                  </Styles.SlickWrapper>
                  <Box className="app-interest-form" marginTop="xxl">
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: parsedHTML.description,
                      }}
                      className="app-full-description"
                    />
                  </Box>
                </Grid.Col>
                <Grid.Col colWidth={colWidthSidebar}>
                  <Box marginTop="md">
                    <Card>
                      {security_badge && renderSecurityCertifiedSection()}
                      <Box padding="lg">
                        {filteredComponents.length > 0 && (
                          <>
                            <H3>Type</H3>
                            <P>{filteredComponents.join(', ')}</P>
                          </>
                        )}
                        {category_names?.length > 0 && (
                          <>
                            <H3>Category</H3>
                            <P>{category_names.join(', ')}</P>
                          </>
                        )}
                        {regions && renderRegions()}
                        <VersionHistory
                          history={versioning_history}
                          showingVersion={version}
                        />
                        <H3>Support</H3>
                        <P>Email</P>
                        <P
                          style={{
                            paddingBottom: '14px',
                            borderBottom: '1px solid #f0f0f0',
                          }}
                        >
                          <Styles.OrangeLink
                            href={`mailto:${email}`}
                            target="_blank"
                            onClick={() =>
                              sendAnalytics(
                                'click',
                                'Link',
                                'Email',
                                'email.clicked'
                              )
                            }
                          >
                            {email}
                          </Styles.OrangeLink>
                        </P>

                        <P>Website</P>
                        <P>
                          <Styles.OrangeLink
                            href={price_button || website}
                            target="_blank"
                            onClick={() =>
                              sendAnalytics(
                                'click',
                                'Link',
                                'Website',
                                'website.clicked'
                              )
                            }
                          >
                            {built_by || name}
                          </Styles.OrangeLink>
                        </P>
                      </Box>
                    </Card>
                  </Box>
                  <Box marginTop="md">
                    <Card>
                      <Box padding="xl">
                        <img
                          src={ imagePath('community.png') }
                          alt="Community"
                          style={{
                            width: '70px',
                            height: '70px',
                            float: 'left',
                            marginRight: '15px',
                          }}
                        />
                        <H3>Ask the Community</H3>
                        <P>
                          Join the
                          <Styles.OrangeLink
                            href="https://community.procore.com/s/topic/0TO2T000000c9HTWAY/integrations-app-marketplace"
                            target="_blank"
                            onClick={() =>
                              sendAnalytics(
                                'click',
                                'Link',
                                'Integrations',
                                'integrations.clicked'
                              )
                            }
                          >
                            {' '}
                            integrations discussion{' '}
                          </Styles.OrangeLink>
                          in the
                          <Styles.OrangeLink
                            href="https://community.procore.com/s/"
                            target="_blank"
                            onClick={() =>
                              sendAnalytics(
                                'click',
                                'Link',
                                'Community',
                                'community.clicked'
                              )
                            }
                          >
                            {' '}
                            Procore Community{' '}
                          </Styles.OrangeLink>
                        </P>
                      </Box>
                    </Card>
                  </Box>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          </Styles.Container>
        </Flex>
      </Flex>
    </>
  );
};

ListingsShow.propTypes = {
  app: PT.shape({
    built_by: PT.string.isRequired,
    email: PT.string.isRequired,
    name: PT.string.isRequired,
    html: PT.string.isRequired,
    installed: PT.bool.isRequired,
    pricing: PT.bool.isRequired,
    create_lead: PT.bool,
    contact_us_email: PT.string,
    search_description: PT.string.isRequired,
    small_logo: PT.shape({
      url: PT.string,
    }).isRequired,
    small_logo_url: PT.string,
    website: PT.string.isRequired,
    category_names: PT.arrayOf(PT.string).isRequired,
    components: PT.arrayOf(PT.string).isRequired,
    developer_app_id: PT.string,
    direct_installable: PT.bool,
    install_path: PT.string,
    price_button: PT.string,
    screenshots: PT.arrayOf(
      PT.shape({
        url: PT.string.isRequired,
      })
    ),
    regions: PT.arrayOf(PT.string),
    countries: PT.shape({}),
    security_badge: PT.bool,
    security_questionnaire_document: PT.string,
  }),
  installationRequest: PT.shape({
    apiPath: PT.string,
    present: PT.bool,
  }),
  companyName: PT.string.isRequired,
  user: PT.shape({
    email: PT.string,
    isCompanyAdmin: PT.bool.isRequired,
    isSignedIn: PT.bool.isRequired,
  }).isRequired,
  companyInstallationRequestsEnabled: PT.bool,
  new_draft_preview: PT.bool,
  metricsData: PT.shape({}),
};

ListingsShow.defaultProps = {
  app: {
    countries: {},
    developer_app_id: -1,
    direct_installable: false,
    install_path: '',
    price_button: false,
    regions: [],
    screenshots: [],
    small_logo_url: '',
    versioning_history: null,
    new_draft_preview: false,
    security_badge: false,
    security_questionnaire_document: '',
  },
  installationRequest: {
    apiPath: '',
    present: false,
  },
  companyInstallationRequestsEnabled: false,
};

export default ListingsShow;
