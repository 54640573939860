import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Flex } from '@procore/core-react';
import { ArrowLeft, ArrowRight } from '@procore/core-icons';
import {
  SlickArrowNext,
  SlickArrowPrev,
  ImageModal,
  ModalImageBody,
  CloseImageModalButton,
  ModalImage,
  SlickWrapper,
  Slide,
  ArrowContainer,
} from '@/react/styles/v2';
import { sendAnalytics } from '../../utils';
import { VideoPreview } from './VideoPreview';

// comment to trigger pr

const MediaSection = ({ screenshots, wistiaId }) => {
  const [currentImageUrl, setCurrentImageUrl] = useState(screenshots[0]);
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const sliderSettings = {
    focusOnSelect: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: true,
    beforeChange: (curr, index) => {
      sendAnalytics('click', 'Media Slider', index, 'media_slider.changed', {
        index,
      });
      setCurrentImageUrl(
        index === 0 && wistiaId
          ? ''
          : screenshots[wistiaId ? index - 1 : index]?.url
      );
    },
    prevArrow: (
      <SlickArrowPrev>
        <Flex paddingRight="lg">
          <ArrowContainer>
            <ArrowLeft size="md" style={{ color: 'black' }} />
          </ArrowContainer>
        </Flex>
      </SlickArrowPrev>
    ),
    nextArrow: (
      <SlickArrowNext>
        <Flex paddingLeft="lg">
          <ArrowContainer>
            <ArrowRight size="md" style={{ color: 'black' }} />
          </ArrowContainer>
        </Flex>
      </SlickArrowNext>
    ),
  };
  return (
    <>
      {screenshots?.length > 0 && (
        <ImageModal
          open={imageModalOpen}
          onClickOverlay={() => setImageModalOpen(false)}
        >
          <ModalImageBody>
            <Box style={{ position: 'relative' }}>
              <CloseImageModalButton onClick={() => setImageModalOpen(false)} />
              <ModalImage src={currentImageUrl || screenshots[0]?.url} alt="" />
            </Box>
          </ModalImageBody>
        </ImageModal>
      )}
      <SlickWrapper
        marginTop="md"
        style={{ width: '644px' }}
        data-testid="media-section"
      >
        <Slider {...sliderSettings}>
          {wistiaId && <VideoPreview wistiaId={wistiaId} />}
          {screenshots?.length === 0 && (
            <p>
              <img
                src={ imagePath('app-image-placeholder.png') }
                alt=""
                style={{ width: '644px', height: '400px' }}
              />
            </p>
          )}
          {screenshots?.map((slide) => (
            <Slide
              src={slide.url}
              key={slide.url}
              onClick={() => setImageModalOpen(true)}
              data-testid={`media-section-slide-${slide.url}`}
            />
          ))}
        </Slider>
      </SlickWrapper>
    </>
  );
};

export default MediaSection;
